import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, CardContent, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import {
  AvailabilityScheduleSelector,
  Checkbox,
  DateRangeSelector,
  DateTimePicker,
  MinMaxNumberInput,
  NumberTextField,
  Select,
} from 'common/components';
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { StatusTypes } from 'common/constants/defaultOptions';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import ClubsSelector from './ClubsSelector';
import EmployeesSelector from './EmployeesSelector';
// hooks
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useAppDispatch } from 'store/hooks';
// constants
import {
  AvailabilityType,
  AvailabilityTypes,
  defaultEditableNumber,
  DurationType,
  DurationTypes,
  PackageCostTypes,
  PackageType,
  PackageTypes,
} from 'modules/services/constants/packages';
import { DictionaryList } from 'common/constants';
import { ServicesSubModules } from 'common/constants/peakModules';
// actions
import { fetchDictionaryList } from 'common/state/dictionary/actions';
// interfaces
import { IFormStepStyle, IGeneralSection } from 'modules/services/interfaces/packages';
// messages
import messages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import packageMessages from 'modules/services/messages/packages';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import ContractTemplateField from './ContractTemplateField';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(3, 0, 1) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(3, 0, 1) : theme.spacing(1, 2),
    },
  },
  mainCheckboxLabel: {
    fontWeight: 500,
  },
}));

interface IGeneralInfoStepFormProps extends IFormStepStyle {
  defaultValues?: IGeneralSection;
}

const GeneralInfoStepForm = ({
  smallPaddingContent,
  defaultValues,
}: IGeneralInfoStepFormProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
    watch,
    trigger,
    clearErrors,
    setValue,
  } = useFormContext<IGeneralSection>();

  const durationType = watch('durationType');
  const durationEditableNumber = watch('durationEditableNumber');
  const type = watch('type');
  const availabilityType = watch('availabilityType');

  watch('durationEditableNumber.editable');

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.CLUBS, ServicesSubModules.ServicePackages));
  }, [dispatch]);

  const startTimePickerName = 'startTime';
  const startDatePickerName = 'startDate';
  const endTimePickerName = 'endTime';
  const endDatePickerName = 'endDate';

  const isForRangeType = availabilityType === AvailabilityType.ForRange;
  const isStartingOnDateType = availabilityType === AvailabilityType.StartingOnDate;

  const startPickerProps = {
    onChange: isForRangeType
      ? () => {
          void trigger(endTimePickerName);
          void trigger(endDatePickerName);
        }
      : null,
  };

  const endPickerProps = {
    onChange:
      isStartingOnDateType || isForRangeType
        ? () => {
            void trigger(startTimePickerName);
            void trigger(startDatePickerName);
          }
        : null,
  };

  const onChangeAvailabilityType = (val: AvailabilityType) => {
    if (val === AvailabilityType.StartingOnDate) {
      /*
       * When you change the 'AvailabilityType', the validation error does not disappear.
       * Added manual error cleanup
       * */
      if (errors.startTime) {
        clearErrors(startTimePickerName);
      }

      if (errors.startDate) {
        clearErrors(startDatePickerName);
      }
    }
  };

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              control={control}
              name="title"
              defaultValue={defaultValues?.title}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  label={<FormattedMessage {...messages.packageNameLabel} />}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.title?.message}
                  helperText={renderIntlMessage(errors.title?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="active"
              control={control}
              defaultValue={defaultValues?.active}
              render={({ field }) => (
                <Select
                  fullWidth
                  label={<FormattedMessage {...commonMessages.statusTitle} />}
                  variant="outlined"
                  name={field.name}
                  value={field.value}
                  onChange={e => field.onChange(e === 'true')}
                  error={!!errors.active?.message}
                  helperText={renderIntlMessage(errors.active?.message)}
                >
                  {StatusTypes.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={6}>
                <Controller
                  control={control}
                  name="type"
                  defaultValue={defaultValues?.type}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...messages.packageTypeLabel} />}
                      variant="outlined"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      {PackageTypes.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Controller
                  control={control}
                  name="durationType"
                  defaultValue={defaultValues?.durationType}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.packageDurationLabel} />}
                      name={field.name}
                      value={field.value}
                      onChange={optionValue => {
                        field.onChange(optionValue);

                        if (optionValue === DurationType.Custom) {
                          setValue('durationEditableNumber', null);
                        } else {
                          if (!durationEditableNumber) {
                            setValue('durationEditableNumber', defaultEditableNumber);
                          }
                        }
                      }}
                      onBlur={field.onBlur}
                      error={!!errors.durationType?.message}
                      helperText={renderIntlMessage(errors.durationType?.message)}
                    >
                      {DurationTypes.getSelectOptions().slice(3)}
                    </Select>
                  )}
                />
              </Grid>
              {durationType === DurationType.Custom ? (
                <Grid item sm={4} xs={12}>
                  <Controller
                    control={control}
                    name="customDates"
                    defaultValue={{
                      startDate: timezoneMoment().add(1, 'd') as any, // TODO - PRM-1810 need types
                      endDate: timezoneMoment().add(1, 'month') as any, // TODO - PRM-1810 need types
                    }}
                    render={({ field }) => (
                      <DateRangeSelector
                        name={field.name}
                        value={field.value as any} // TODO - PRM-3575 need fix
                        onChange={field.onChange}
                        error={!!errors.customDates}
                        helperText={renderIntlMessage(
                          errors.customDates?.message ||
                            errors.customDates?.startDate?.message ||
                            errors.customDates?.endDate?.message,
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item sm={4} xs={12}>
                  <MinMaxNumberInput
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    baseName="durationEditableNumber"
                    label={<FormattedMessage {...messages.amountLabel} />}
                    baseDefaultValue={defaultValues?.durationEditableNumber}
                  />
                </Grid>
              )}
              {type === PackageType.Trial && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        control={control}
                        name="costType"
                        render={({ field }) => (
                          <Select
                            fullWidth
                            variant="outlined"
                            label={<FormattedMessage {...messages.packageCostTypeLabel} />}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            error={!!errors.costType?.message}
                            helperText={renderIntlMessage(errors.costType?.message)}
                          >
                            {PackageCostTypes.getSelectOptions()}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {PackageType.Trial !== type && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="renewalNumber"
                    render={({ field }) => (
                      <NumberTextField
                        numberFormatProps={defaultNumberFormatProps}
                        variant="outlined"
                        label={<FormattedMessage {...messages.renewalsLabel} />}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.renewalNumber}
                        helperText={renderIntlMessage(errors.renewalNumber?.message)}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="availabilityType"
                  defaultValue={defaultValues?.availabilityType}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.availabilityType} />}
                      name={field.name}
                      value={field.value}
                      onChange={(val: AvailabilityType) => {
                        field.onChange(val);
                        onChangeAvailabilityType(val);
                      }}
                      onBlur={field.onBlur}
                      error={!!errors.availabilityType?.message}
                      helperText={renderIntlMessage(errors.availabilityType?.message)}
                    >
                      {AvailabilityTypes.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {(isStartingOnDateType || isForRangeType) && (
                    <Grid item xs={6}>
                      <DateTimePicker
                        timePickerName={startTimePickerName}
                        datePickerName={startDatePickerName}
                        dateLabelMessageDescriptor={inputLabels.startDate}
                        timeLabelMessageDescriptor={inputLabels.startTime}
                        timePickerProps={startPickerProps as any} // TODO - PRM-3575 need fix
                        datePickerProps={startPickerProps as any} // TODO - PRM-3575 need fix
                      />
                    </Grid>
                  )}
                  {isForRangeType && (
                    <Grid item xs={6}>
                      <DateTimePicker
                        timePickerName={endTimePickerName}
                        datePickerName={endDatePickerName}
                        timePickerProps={endPickerProps as any} // TODO - PRM-3575 need fix
                        datePickerProps={endPickerProps as any} // TODO - PRM-3575 need fix
                        dateLabelMessageDescriptor={inputLabels.endDate}
                        timeLabelMessageDescriptor={inputLabels.endTime}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="membership"
                  defaultValue={defaultValues?.membership}
                  render={({ field }) => (
                    <FormControlLabel
                      label={
                        <Typography className={classes.mainCheckboxLabel}>
                          <FormattedMessage {...messages.membershipPackageLabel} />
                        </Typography>
                      }
                      control={
                        <Checkbox
                          size="small"
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <ContractTemplateField />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="allowMembersToPurchaseOnline"
                  render={({ field }) => (
                    <CheckBoxWithReset
                      value={field.value}
                      onChange={field.onChange}
                      label={
                        <Typography className={classes.mainCheckboxLabel}>
                          <FormattedMessage {...messages.allowMembersToPurchaseOnlineLabel} />
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="dayTimeAvailDtoList"
                  render={({ field }) => (
                    <AvailabilityScheduleSelector
                      defaultValue={defaultValues?.dayTimeAvailDtoList}
                      defaultOptionLabel={<FormattedMessage {...inputLabels.accessAsClub} />}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Grid>

              <EmployeesSelector control={control} />

              <ClubsSelector
                control={control}
                fieldName="sellerClubs"
                label={<FormattedMessage {...packageMessages.limitSellerClubs} />}
              />
              <ClubsSelector
                control={control}
                fieldName="accessClubs"
                label={<FormattedMessage {...packageMessages.limitClubs} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default React.memo(GeneralInfoStepForm);
