import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { addInvoiceUnit } from 'common/state/invoice/actions';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';

import { DialogComponent } from 'common/components';
import PastDuesStep from './PastDuesStep/PastDuesStep';
import PaymentStep from 'common/components/PersonProfile/components/PaymentStep/PaymentStep';

import { IPastDue } from 'common/components/PersonProfile/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import { CartUnitTypes, IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';

import { PeakModules } from 'common/constants/peakModules';
import { EXECUTE_MEMBERSHIP_ACTIONS } from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';

import { useAppDispatch } from 'store/hooks';

import messages from 'common/components/PersonProfile/messages';
import { resolvePastDueAlert } from 'common/components/PersonProfile/state/actions';
import { selectCancelResult } from 'common/state/invoice/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';

interface IProps {
  isOpen: boolean;
  personId: number;
  alertId?: AlertCondition;
  alertUuid?: string;
  onSubmit: (dues: IPastDue[], alertId?: AlertCondition) => void;
  onCancel: (alertId?: AlertCondition) => void;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  disablePosBtn: boolean;
  isOpenPOSPanel: boolean;
  module: PeakModules;
}

const useStyles = makeStyles(() => ({
  customPaper: {
    '& .MuiDialog-container>.MuiDialog-paperScrollPaper': {
      overflowY: 'hidden',
    },
  },
}));

const { addDelayedAction } = StorageServices.delayedActions;

export enum PastDuesSteps {
  PastDues,
  Payment,
}

const PastDuesResolutionModal = ({
  isOpen,
  onSubmit,
  onCancel,
  alertId,
  alertUuid,
  personId,
  module,
  disablePosBtn,
  isOpenPOSPanel,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedRegisterId: string = useRootSelector(selectSelectedRegisterId);
  const cancelInvoiceResult = useRootSelector(selectCancelResult());
  const currentUserId = useRootSelector(selectCurrentUserId);

  const [currentStep, setCurrentStep] = useState<PastDuesSteps>(PastDuesSteps.PastDues);
  const [autoSelectRegister, setAutoSelectRegister] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (cancelInvoiceResult?.get('success') && cancelInvoiceResult.get('shouldGoBack')) {
      setCurrentStep(PastDuesSteps.PastDues);
    }
  }, [cancelInvoiceResult, setCurrentStep]);

  const handleSubmit = (): void => {
    const selectedItems = [];

    onSubmit(selectedItems, alertId);
  };

  const handleSubmitPastDuesStep = async (pastDueIds: string[]) => {
    if (disablePosBtn || isOpenPOSPanel) {
      if (isOpenPOSPanel) {
        handlePOSPanelChange(false);
      }

      handleNewMemberPanelChange(false);
      handleChangePackagePlanChange(false);
    }

    const handleCreateInvoice = (invoicesList: IInvoiceDetailsImt[], registerId: string) => {
      const openedInvoiceForSelectedCustomer = invoicesList?.find(
        (invoiceItem: IInvoiceDetailsImt) => {
          return (
            invoiceItem.get('status') === InvoiceStatus.OPEN &&
            invoiceItem.getIn(['customer', 'id']) === personId &&
            invoiceItem.getIn(['salesperson', 'id']) === currentUserId
          );
        },
      );

      if (openedInvoiceForSelectedCustomer) {
        dispatch(
          addInvoiceUnit(
            openedInvoiceForSelectedCustomer.get('id'),
            {
              pastDues: pastDueIds,
              memberId: personId,
              registerId: selectedRegisterId,
            },
            CartUnitTypes.PAST_DUE,
            module,
            true,
            true,
          ),
        );
      } else {
        dispatch(
          addInvoiceUnit(
            null,
            {
              createNewInvoice: true,
              pastDues: pastDueIds,
              memberId: personId,
              registerId,
            },
            CartUnitTypes.PAST_DUE,
            module,
            true,
            true,
          ),
        );
      }
    };

    addDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS, handleCreateInvoice);

    setCurrentStep(PastDuesSteps.Payment);
  };

  const handleCancel = (): void => {
    onCancel(alertId);
  };

  const handleClosePaymentStep = useCallback(() => {
    setCurrentStep(PastDuesSteps.PastDues);
  }, []);

  const handleConfirmPaymentStep = useCallback(() => {
    dispatch(resolvePastDueAlert(AlertCondition.PastDuePayments, personId));

    onCancel(alertId);
  }, [dispatch, personId, onCancel, alertId]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onClose={handleCancel}
      loading={false}
      hasCustomFooter
      size="lg"
      title={<FormattedMessage {...messages.pastDuesModalTitle} />}
      disableBodyPadding={currentStep === PastDuesSteps.Payment}
      className={classes.customPaper}
    >
      {currentStep === PastDuesSteps.PastDues && (
        <PastDuesStep
          personId={personId}
          alertUuid={alertUuid}
          setCurrentStep={setCurrentStep}
          setAutoSelectRegister={setAutoSelectRegister}
          onCancel={handleCancel}
          onSubmit={handleSubmitPastDuesStep}
          module={module}
        />
      )}

      {currentStep === PastDuesSteps.Payment && (
        <PaymentStep
          personId={personId}
          disableInvoiceCreation
          disableInvoiceSelection
          onCancel={handleClosePaymentStep}
          module={module}
          onNext={handleConfirmPaymentStep}
          autoSelectRegister={autoSelectRegister}
        />
      )}
    </DialogComponent>
  );
};

export default PastDuesResolutionModal;
