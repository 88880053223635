import { createAction } from '@reduxjs/toolkit';

import { actionTypes } from 'modules/my-profile/state/profile/constants';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import {
  IEmployeeCredentialsUpdateDto,
  IEmployeeJobPosition,
  IEmployeeDto,
  ISecurityRoleToClubListDto,
  IEmployeeProfileDto,
} from 'common/interfaces/employee';
import { IAttachment } from 'common/interfaces/uploadFile';
import { setUserAvatar } from 'modules/authentication/state/actions';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedTimeclockList } from 'modules/timeclock/interfaces/timeclock';

export const clearMyProfile = createAction(actionTypes.CLEAR_MY_PROFILE);
const fetchMyProfileViewLoading = createAction<boolean>(actionTypes.FETCH_MY_PROFILE_VIEW_LOADING);
const fetchMyProfileViewSuccess = createAction<IEmployeeProfileDto>(
  actionTypes.FETCH_MY_PROFILE_VIEW_SUCCESS,
);

export const fetchMyProfileView = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMyProfileViewLoading(true));
    try {
      const myProfile = await Services.Authentication.getFullProfileView();
      dispatch(fetchMyProfileViewSuccess(myProfile));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMyProfileViewLoading(false));
    }
  };
};

const fetchMyProfileLoading = createAction<boolean>(actionTypes.FETCH_MY_PROFILE_LOADING);
const fetchMyProfileSuccess = createAction<IEmployeeDto>(actionTypes.FETCH_MY_PROFILE_SUCCESS);

export const fetchMyProfile = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMyProfileLoading(true));
    try {
      const myProfile = await Services.Authentication.getFullProfile();
      dispatch(fetchMyProfileSuccess(myProfile));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMyProfileLoading(false));
    }
  };
};

export const updateMyProfile = (data: IEmployeeDto): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMyProfileViewLoading(true));
    try {
      await Services.Authentication.updateFullProfile(data);
      const myProfile = await Services.Authentication.getFullProfileView();

      dispatch(fetchMyProfileViewSuccess(myProfile));
      const { preferredName, image } = myProfile;

      if (preferredName && image?.url) {
        dispatch(setUserAvatar({ preferredName, avatar: image.url }));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMyProfileViewLoading(false));
    }
  };
};

const fetchProfilePermissionsLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_PERMISSIONS_LOADING,
);
const fetchProfilePermissionsSuccess = createAction<ISecurityRoleToClubListDto[]>(
  actionTypes.FETCH_MY_PROFILE_PERMISSIONS_SUCCESS,
);

export const fetchProfilePermissions = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfilePermissionsLoading(true));
    try {
      const permissions = await Services.Authentication.getProfilePermissions();
      dispatch(fetchProfilePermissionsSuccess(permissions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfilePermissionsLoading(false));
    }
  };
};

const fetchProfileAttachmentsLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_ATTACHMENTS_LOADING,
);
const fetchProfileAttachmentsSuccess = createAction<IAttachment[]>(
  actionTypes.FETCH_MY_PROFILE_ATTACHMENTS_SUCCESS,
);

export const fetchProfileAttachments = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileAttachmentsLoading(true));
    try {
      const attachments = await Services.Authentication.getProfileAttachments();
      dispatch(fetchProfileAttachmentsSuccess(attachments));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileAttachmentsLoading(false));
    }
  };
};

export const deleteProfileAttachment = (attachmentId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileAttachmentsLoading(true));
    try {
      await Services.Authentication.deleteProfileAttachment(attachmentId);
      const response = await Services.Authentication.getProfileAttachments();

      dispatch(fetchProfileAttachmentsSuccess(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileAttachmentsLoading(false));
    }
  };
};

export const updateProfileAttachments = (attachments: IAttachment[]): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileAttachmentsLoading(true));
    try {
      await Services.Authentication.updateProfileAttachments(attachments);
      const response = await Services.Authentication.getProfileAttachments();

      dispatch(fetchProfileAttachmentsSuccess(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileAttachmentsLoading(false));
    }
  };
};

export const updateProfileAvatar = (attachment: IAttachment): GeneralThunkAction => {
  return async dispatch => {
    try {
      await Services.Authentication.uploadPhoto(attachment);
      const myProfile = await Services.Authentication.getFullProfileView();

      dispatch(fetchMyProfileViewSuccess(myProfile));
      const { preferredName, image } = myProfile;

      if (preferredName && image?.url) {
        dispatch(setUserAvatar({ preferredName, avatar: image.url }));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

export const deleteProfileAvatar = (): GeneralThunkAction => {
  return async dispatch => {
    try {
      await Services.Authentication.deletePhoto();
      const myProfile = await Services.Authentication.getFullProfileView();

      dispatch(fetchMyProfileViewSuccess(myProfile));
      const { preferredName, image } = myProfile;

      if (preferredName && image?.url) {
        dispatch(setUserAvatar({ preferredName, avatar: image.url }));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

const fetchProfileJobPositionsLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_LOADING,
);
const fetchProfileJobPositionsSuccess = createAction<IEmployeeJobPosition[]>(
  actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_SUCCESS,
);

export const getProfileJobPositions = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileJobPositionsLoading(true));
    try {
      const jobPositions = await Services.Authentication.getProfileJobPositions();

      dispatch(fetchProfileJobPositionsSuccess(jobPositions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileJobPositionsLoading(false));
    }
  };
};

export const resetMyProfileJobPositionsHistory = createAction(
  actionTypes.RESET_MY_PROFILE_JOB_POSITIONS_HISTORY,
);
const fetchMyProfileJobPositionsHistoryLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_LOADING,
);
const fetchMyProfileJobPositionsHistorySuccess = createAction<IEmployeeJobPosition[]>(
  actionTypes.FETCH_MY_PROFILE_JOB_POSITIONS_HISTORY_SUCCESS,
);

export const getMyProfileJobPositionsHistory = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMyProfileJobPositionsHistoryLoading(true));
    try {
      const jobPositions = await Services.Authentication.getProfileJobPositionsHistory();

      dispatch(fetchMyProfileJobPositionsHistorySuccess(jobPositions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMyProfileJobPositionsHistoryLoading(false));
    }
  };
};

export const updateProfileCredentials = (
  credentials: IEmployeeCredentialsUpdateDto,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMyProfileViewLoading(true));
    try {
      await Services.Authentication.updateProfileCredentials(credentials);
      const myProfile = await Services.Authentication.getFullProfileView();
      dispatch(fetchMyProfileViewSuccess(myProfile));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchMyProfileViewLoading(false));
    }
  };
};

const fetchProfileTimeclockHistoryLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_TIMECLOCK_HISTORY_LOADING,
);
const fetchProfileTimeclockHistorySuccess = createAction<IPaginatedTimeclockList>(
  actionTypes.FETCH_MY_PROFILE_TIMECLOCK_HISTORY_SUCCESS,
);

export const getProfileTimeclockHistory = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileTimeclockHistoryLoading(true));
    try {
      const jobPositions = await Services.Authentication.getProfileTimeclockHistory(requestOptions);

      dispatch(fetchProfileTimeclockHistorySuccess(jobPositions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileTimeclockHistoryLoading(false));
    }
  };
};

const fetchProfileRecentTimeclockHistoryLoading = createAction<boolean>(
  actionTypes.FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_LOADING,
);
const fetchProfileRecentTimeclockHistorySuccess = createAction<IPaginatedTimeclockList>(
  actionTypes.FETCH_MY_PROFILE_RECENT_TIMECLOCK_HISTORY_SUCCESS,
);

export const getProfileRecentTimeclockHistory = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileRecentTimeclockHistoryLoading(true));
    try {
      const jobPositions = await Services.Authentication.getProfileRecentTimeclockHistory();

      dispatch(fetchProfileRecentTimeclockHistorySuccess(jobPositions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileRecentTimeclockHistoryLoading(false));
    }
  };
};
