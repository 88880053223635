import React, { useContext, useEffect, useRef } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import isNumber from 'lodash/isNumber';

import { colors } from 'common/ui/theme/default';
// interfaces
import {
  CartUnitTypes,
  IInvoiceDetailsDto,
  IInvoiceDetailsImt,
  InvoiceStatus,
} from 'common/interfaces/invoices';
// state
import * as actions from 'common/state/invoice/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// constants
import { ENotificationType } from 'modules/booking/constants/notificationType';
import { PermissionLevels } from 'common/constants/permissions';
import { PeakModules } from 'common/constants/peakModules';
// ValidationSchema
import { PaymentStepValidationSchema } from 'common/components/Steps/PaymentStep/ValidationSchema/ValidationSchema';
// components
import { InvoiceOperating, RegisterSelector } from 'common/components/index';
import { StepContext } from 'common/createContext/stepContext';
// messages
import messages from 'common/messages/messages';
import { ImmutableObject } from 'common/state/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IBaseInvoicePackageImt } from 'common/interfaces/membership';
import { selectPosKioskRegisterData } from 'modules/pos-kiosk/state/register/selectors';
import { IPosKioskRegisterDataImt } from 'modules/pos-kiosk/interfaces/registers';
import {
  selectCurrentUserId,
  selectCurrentUserSelectedLevel,
  selectUserSelectedOrganization,
} from 'modules/authentication/state/selectors';
import {
  fetchPersonMembership,
  resetPersonMembership,
} from 'common/state/newPerson/membership/actions';
import { selectPersonMembership } from 'common/state/newPerson/membership/selectors';
import { IPersonSalesInfoImt } from 'common/components/PersonProfile/interfaces';
import { selectPersonSalesInfo } from 'common/components/PersonProfile/state/selectors';
import { selectCurrentInvoice } from 'common/state/invoice/selectors';
import isFormDirty from 'common/hooks/isFormDirty';
import useDiscardChangesContext from 'common/hooks/useDiscardChangesContext';
import DiscardChangesModalProvider from 'common/modals/DiscardChangesModal/DiscardChangesModalProvider';
import SignContractService from 'services/application/SignContractService';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

interface IProps {
  module: PeakModuleForNewPersonType;
  person: ImmutableObject<any>;
  registerId?: string;
  currentRegisterInvoicesList: List<IInvoiceDetailsImt> | null;
}

const PaymentStep = ({
  module,
  registerId,
  person,
  currentRegisterInvoicesList,
}: IProps): JSX.Element => {
  const { onBack, onNext, renderFooter } = useContext(StepContext);
  const dcContext = useDiscardChangesContext();
  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(PaymentStepValidationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, formState } = formMethods;
  const isDirty = isFormDirty(formState);
  const dispatch = useAppDispatch();

  const personMembership: IBaseInvoicePackageImt = useRootSelector(selectPersonMembership);
  const currentUserId = useRootSelector(selectCurrentUserId);
  const selectedPermissionsLevel = useRootSelector(selectCurrentUserSelectedLevel);
  const salesInfo: IPersonSalesInfoImt = useRootSelector(selectPersonSalesInfo(person.get('id')));
  const selectedOrg = useRootSelector(selectUserSelectedOrganization);
  const currentRegisterData: IPosKioskRegisterDataImt = useRootSelector(selectPosKioskRegisterData);
  const selectedInvoice = useRootSelector(selectCurrentInvoice);
  const isCurrentRegisterOpen = currentRegisterData?.get('open');

  const isInvoiceCreatedOrUpdated = useRef(false);
  const invoicesHaveBeenRequestedRef = useRef(false);
  invoicesHaveBeenRequestedRef.current = Boolean(currentRegisterInvoicesList);
  const invoicesHaveBeenRequested = invoicesHaveBeenRequestedRef.current;

  const isDisabledRegister = selectedInvoice?.get('status') === InvoiceStatus.IN_PROGRESS;
  const selectedOrgId = selectedOrg?.get('id');
  const isCorporateLevel = selectedPermissionsLevel === PermissionLevels.CORPORATE;
  const posClubId = isCorporateLevel ? null : selectedOrgId;

  const event = (): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_RECEIPT_SENDING;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_RECEIPT_SENDING;
      case PeakModules.FrontDesk:
        return ENotificationType.FRONTDESK_RECEIPT_SENDING;
      case PeakModules.Members:
        return ENotificationType.MEMBER_RECEIPT_SENDING;
      default:
        return ENotificationType.POS_MODULE_RECEIPT_SENDING;
    }
  };

  // effects

  useEffect(() => {
    if (personMembership.size) {
      return;
    }

    // const isCrmModule =
    //   module === PeakModules.Crm ||
    //   module === PeakModules.PersonalTrainingCrm ||
    //   module === PeakModules.Members;
    // const salesPersonId = salesInfo?.getIn(['assignedSalesperson', 'id']);
    // const employeeId = isCrmModule
    //   ? salesPersonId
    //   : person?.getIn(['salesperson', 'id']) || currentUserId;
    //
    // if (employeeId) {
    //   dispatch(fetchPersonMembership(person?.get('id'), employeeId, module));
    // } else if (isCrmModule) {
    //   dispatch(fetchPersonSalesInfo(person?.get('id'), module));
    // }

    // TODO: Temporarily does not pass the employeeId. This value is taken from the token
    const employeeId = null;

    dispatch(fetchPersonMembership(person?.get('id'), employeeId, module));
  }, [dispatch, person, module, salesInfo, personMembership, currentUserId]);

  // select other register
  // load all it's data

  useEffect(() => {
    if (
      !registerId ||
      isInvoiceCreatedOrUpdated.current ||
      !isCurrentRegisterOpen ||
      !personMembership.size ||
      !invoicesHaveBeenRequested
    ) {
      return;
    }

    const invoiceList = currentRegisterInvoicesList?.toJS();
    const openedInvoiceForSelectedCustomer = invoiceList?.find(invoiceItem => {
      const expectedCustomerId = person?.get('id');

      return (
        invoiceItem.customer &&
        invoiceItem.salesperson &&
        invoiceItem.customer.id === expectedCustomerId &&
        invoiceItem.salesperson.id === currentUserId &&
        invoiceItem.status === InvoiceStatus.OPEN
      );
    });

    isInvoiceCreatedOrUpdated.current = true;

    const onSuccess = (invoiceUnitDetails: IInvoiceDetailsDto): void => {
      const { invoiceUnits = [] } = invoiceUnitDetails;
      const packageInstanceIds: number[] = [];

      for (const invoiceUnit of invoiceUnits) {
        if (invoiceUnit.bundle && isNumber(invoiceUnit.bundle.instanceId)) {
          packageInstanceIds.push(invoiceUnit.bundle.instanceId);
        }
      }

      SignContractService.setPackageInstanceIds(packageInstanceIds);
    };

    if (openedInvoiceForSelectedCustomer) {
      dispatch(
        actions.addInvoiceUnit(
          openedInvoiceForSelectedCustomer.id,
          {
            memberId: openedInvoiceForSelectedCustomer.customer?.id ?? null,
            membershipPackageInstanceId: personMembership.get('id'),
          },
          CartUnitTypes.MEMBERSHIP_BUNDLE,
          module,
          true,
          true,
          onSuccess,
        ),
      );
    } else {
      dispatch(
        actions.addInvoiceUnit(
          null,
          {
            createNewInvoice: true,
            memberId: person.get('id'),
            membershipPackageInstanceId: personMembership.get('id'),
            registerId,
          },
          CartUnitTypes.MEMBERSHIP_BUNDLE,
          module,
          true,
          true,
          onSuccess,
        ),
      );
    }
  }, [
    dispatch,
    person,
    registerId,
    isCurrentRegisterOpen,
    personMembership,
    module,
    invoicesHaveBeenRequested,
    currentUserId,
    currentRegisterInvoicesList,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetPersonMembership());
    };
  }, [dispatch]);

  // renders

  const renderPanelHeader = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        pb={2.5}
        borderBottom={`1px solid ${colors.lightGray}`}
        borderTop={`1px solid ${colors.lightGray}`}
      >
        <RegisterSelector
          disabled={isDisabledRegister}
          isInvoiceView
          module={module}
          clubId={posClubId}
        />
      </Box>
    );
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          id="payment-form"
          className={classes.root}
          onSubmit={handleSubmit(onNext)}
          autoComplete="none"
        >
          {personMembership?.get('id') ? (
            <Box display="flex" flexDirection="column" height="100%">
              <Box flex="0 1 auto">{renderPanelHeader()}</Box>
              <Box flex="1 1 auto">
                <InvoiceOperating
                  module={module}
                  isPaymentStep
                  isSalespersonSelectDisabled
                  isCustomerSelectDisabled
                  shouldGoBackAfterRevokeInvoice
                  clubId={posClubId}
                  onBack={onBack}
                  onNext={() => {
                    onNext();
                  }}
                  event={event()}
                />
              </Box>
            </Box>
          ) : (
            <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
              <Box p={2} display="flex" justifyContent="center">
                <Typography color="textSecondary">
                  <FormattedMessage {...messages.noMembershipPackageSelected} />
                </Typography>
              </Box>
              {!!renderFooter && renderFooter(onBack, handleSubmit(onNext))}
            </Box>
          )}
        </form>
      </FormProvider>

      {dcContext && isDirty ? <DiscardChangesModalProvider {...dcContext} /> : null}
    </>
  );
};

export default PaymentStep;
