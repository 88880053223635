import React, { useCallback, useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// interfaces
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';
// constants
import { ActionResult, DocumentUrls, QueryPageList } from 'common/constants';
// state
import * as actions from 'modules/services/state/documentTemplates/actions';
import * as selectors from 'modules/services/state/documentTemplates/selectors';
import { selectQueryPageList } from 'common/state/queryPage-lists/selectors';
// utils
import { removeLastRouteFromPath } from 'helpers';
import { convertToEditor } from 'common/utils/draftJS';
// hooks
import { useAppDispatch } from 'store/hooks';
// components
import { RouteBackground } from 'components';
import { TemplateForm } from 'modules/services/components';
import { LoadingBackdrop } from 'common/components';
// messages
import messages from 'modules/services/messages/templates';

const EditTemplate = (): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const templateLoading: boolean = useRootSelector(selectors.selectTemplateLoading);
  const template: IDocumentTemplateDtoImt = useRootSelector(selectors.selectTemplate);
  const queryDocumentTemplates: string = useRootSelector(
    selectQueryPageList(QueryPageList.SERVICE_DOCUMENT_TEMPLATES),
  );
  const documentTemplateActionResult: ActionResult = useRootSelector(
    selectors.selectSaveTemplateActionResult,
  );
  const saveTemplateLoading: boolean = useRootSelector(selectors.selectSaveDocumentTemplateLoading);

  const [defaultEditor, setDefaultEditor] = useState(undefined);

  const location = useLocation();
  const navigate = useNavigate();
  const { id: editId } = useParams();

  const documentType = DocumentUrls[location.pathname.split('/')[3]];

  // handlers

  const updateEditor = useCallback(() => {
    setDefaultEditor(convertToEditor(template.get('content')));
  }, [template]);

  const onCancel = useCallback(() => {
    navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
  }, [location.pathname, navigate, queryDocumentTemplates]);

  const onSave = useCallback(
    (value: ICreateDocumentTemplateDto) => {
      const document: any = {
        ...value,
        id: editId,
      };

      if (editId) {
        dispatch(actions.updateDocumentTemplate(document));
      } else {
        dispatch(actions.saveDocumentCreator(document));
      }
    },
    [dispatch, editId],
  );

  // lifecycle

  useEffect(() => {
    if (editId) {
      dispatch(actions.getTemplate(editId));
    }
  }, [dispatch, editId]);

  useEffect(() => {
    if (template) {
      updateEditor();
    }

    if (documentTemplateActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.saveTemplateActionResult(null));

      navigate(`${removeLastRouteFromPath(location.pathname)}${queryDocumentTemplates}`);
    }
  }, [
    dispatch,
    documentTemplateActionResult,
    location.pathname,
    navigate,
    queryDocumentTemplates,
    template,
    updateEditor,
  ]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetTemplate());
    };
  }, [dispatch]);

  return (
    <RouteBackground>
      {((defaultEditor && editId) || !editId) && (
        <TemplateForm
          onCancel={onCancel}
          onSave={onSave}
          defaultEditor={defaultEditor}
          template={template}
          isLoading={saveTemplateLoading}
          title={
            <FormattedMessage
              values={{ title: documentType }}
              {...(editId
                ? messages.editDocumentTemplatePageTitle
                : messages.newDocumentTemplatePageTitle)}
            />
          }
        />
      )}
      <LoadingBackdrop isLoading={templateLoading} />
    </RouteBackground>
  );
};

export default EditTemplate;
