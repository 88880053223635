import { batch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import Services from 'services/network';

import { actionTypes } from 'modules/kid-zone/constants';
import { CURRENT_CHECKIN_TIME_MIN } from 'modules/front-desk/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';
import {
  fetchProfileInfoAction,
  fetchProfileInfoLoadingAction,
} from 'common/components/PersonProfile/state/actions';
import { setMainPanelPersonAction } from 'modules/front-desk/state/actions';

import { mapCheckInDto } from 'common/utils';

import { GeneralThunkAction } from 'common/state/interfaces';
import {
  ICheckInManuallySearch,
  IFoundMembersToCheckinByBarcodeError,
  IGuardianDayCareService,
  IKidZoneAutocompleteCustomer,
  IKidZoneBarcodeCheckinDto,
  IKidZoneStatus,
} from 'modules/kid-zone/interfaces';
import { ICheckIn } from 'modules/front-desk/interfaces';
import { IPersonSearchBarValues } from 'common/components/ManualCheckInPanel/components/PersonSearchBar/interfaces';
import { ICheckInHistory } from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ActionResult } from 'common/constants';
import { IAllergy, ICustomerEmergencyContactDto } from 'common/interfaces/additionalInfo';
import { IAllergiesFormValues } from 'modules/members/interfaces';

export const setKidZoneMainPanelPersonAction = createAction<number | null>(
  actionTypes.SET_MAIN_PANEL_PERSON,
);
export const setKidZoneRightPanelPersonAction = createAction<number | null>(
  actionTypes.SET_RIGHT_PANEL_PERSON,
);
export const setKidZoneSelectedCheckInAction = createAction<number | null>(
  actionTypes.SET_SELECTED_CHECK_IN,
);
export const setKidZoneCurrentCheckInAction = createAction<ICheckIn | null>(
  actionTypes.SET_CURRENT_CHECK_IN,
);
export const resetKidZoneStatusAction = createAction(actionTypes.RESET_KID_ZONE_STATUS);
export const resetCurrentCheckinAction = createAction(actionTypes.RESET_CURRENT_CHECK_IN);
export const resetCheckInActionStatus = createAction(actionTypes.RESET_CHECK_IN_ACTION_STATUS);
const checkOutKidAction = createAction<ICheckIn>(actionTypes.CHECK_OUT_KID);

const getMembersAction = createAction<ICheckInManuallySearch[]>(actionTypes.GET_MEMBERS);
const getMembersLoadingAction = createAction<boolean>(actionTypes.GET_MEMBERS_LOADING);
export const resetMembersAction = createAction(actionTypes.RESET_MEMBERS);

const fetchKidZoneStatusAction = createAction<IKidZoneStatus>(actionTypes.KID_ZONE_STATUS);
const fetchKidZoneStatusLoadingAction = createAction<boolean>(actionTypes.KID_ZONE_STATUS_LOADING);
export const fetchKidZoneStatus = (clubId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchKidZoneStatusLoadingAction(true));
    try {
      const kidZoneStatus = await Services.KidZone.fetchKidZoneStatus(clubId);
      dispatch(fetchKidZoneStatusAction(kidZoneStatus || null));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchKidZoneStatusLoadingAction(false));
    }
  };
};

export const searchMembers = (searchValues: IPersonSearchBarValues): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getMembersLoadingAction(true));

    try {
      const membersList = await Services.KidZone.getMembersBySearch(searchValues);

      dispatch(getMembersAction(membersList));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getMembersLoadingAction(false));
    }
  };
};

const fetchRecentCheckInsAction = createAction<ICheckIn[]>(actionTypes.FETCH_CHECK_INS);
const fetchRecentCheckInsLoadingAction = createAction<boolean>(actionTypes.FETCH_CHECK_INS_LOADING);

export const fetchRecentCheckIns = (clubId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentCheckInsLoadingAction(true));

    try {
      const checkIns = await Services.KidZone.getRecentCheckIns(clubId);

      const mappedCheckIns = checkIns.map(mapCheckInDto);

      if (
        mappedCheckIns.length &&
        moment(mappedCheckIns[0].checkInTime).isAfter(
          moment().subtract(CURRENT_CHECKIN_TIME_MIN, 'minutes'),
        )
      ) {
        const currentCheckIn = mappedCheckIns.shift();

        if (currentCheckIn) {
          dispatch(setKidZoneCurrentCheckInAction(currentCheckIn));
        }
      }

      dispatch(fetchRecentCheckInsAction(mappedCheckIns));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentCheckInsLoadingAction(false));
    }
  };
};

const fetchCheckInsHistoryAction = createAction<IPaginatedData<ICheckInHistory>>(
  actionTypes.FETCH_CHECK_INS_HISTORY,
);
const fetchCheckInsHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_CHECK_INS_HISTORY_LOADING,
);

export const fetchCheckInsHistory = (
  tableParams?: ITableParams,
  clubId?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCheckInsHistoryLoadingAction(true));

    try {
      const checkInsHistory = await Services.KidZone.getCheckInsHistory(tableParams, clubId);

      dispatch(fetchCheckInsHistoryAction(checkInsHistory));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCheckInsHistoryLoadingAction(false));
    }
  };
};

const setIsLoading = createAction<boolean>(actionTypes.SET_ALLERGIES_IS_LOADING);

const fetchAllergiesSuccess = createAction<IAllergy[]>(actionTypes.FETCH_ALLERGIES);

export const resetAllergies = createAction(actionTypes.RESET_ALLERGIES);

export const fetchAllergiesThunk = (personId: number) => {
  return async dispatch => {
    try {
      dispatch(setIsLoading(true));

      const response = await Services.KidZone.getAllergies(personId);

      dispatch(fetchAllergiesSuccess(response));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

const updateAllergySuccess = createAction<IAllergy>(actionTypes.UPDATE_ALLERGIES);

export const updateAllergiesThunk = (personId: number, requestData: IAllergiesFormValues) => {
  return async dispatch => {
    try {
      dispatch(setIsLoading(true));

      const response = await Services.KidZone.updateAllergies(personId, requestData);

      dispatch(updateAllergySuccess(response));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

const setEmergencyContactLoading = createAction<boolean>(actionTypes.SET_EMERGENCY_CONTACT_LOADING);

const fetchEmergencyContactSuccess = createAction<ICustomerEmergencyContactDto>(
  actionTypes.FETCH_EMERGENCY_CONTACT,
);

export const resetEmergencyContact = createAction(actionTypes.RESET_EMERGENCY_CONTACT);

export const fetchEmergencyContactThunk = (childId: number) => {
  return async dispatch => {
    try {
      dispatch(setEmergencyContactLoading(true));

      const response = await Services.KidZone.getEmergencyContact(childId);

      dispatch(fetchEmergencyContactSuccess(response));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setEmergencyContactLoading(false));
    }
  };
};

const updateEmergencyContactSuccess = createAction<ICustomerEmergencyContactDto>(
  actionTypes.UPDATE_EMERGENCY_CONTACT,
);

export const updateEmergencyContactThunk = (
  childId: number,
  requestData: ICustomerEmergencyContactDto,
) => {
  return async dispatch => {
    try {
      dispatch(setEmergencyContactLoading(true));

      const response = await Services.KidZone.updateEmergencyContact(childId, requestData);

      dispatch(updateEmergencyContactSuccess(response));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setEmergencyContactLoading(false));
    }
  };
};

const checkInChildAction = createAction<ActionResult>(actionTypes.CHECK_IN_CHILD);
const checkInChildLoadingAction = createAction<boolean>(actionTypes.CHECK_IN_CHILD_LOADING);

export const checkInKid = (
  personId: number,
  broughtCustomerId: number,
  serviceInstanceId: string,
  clubId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(checkInChildLoadingAction(true));

    try {
      const checkInResponse = await Services.KidZone.checkInKid(
        personId,
        broughtCustomerId,
        serviceInstanceId,
        clubId,
      );

      const mappedCheckIn = mapCheckInDto(checkInResponse);

      batch(() => {
        dispatch(setKidZoneCurrentCheckInAction(mappedCheckIn));
        dispatch(checkInChildAction(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(checkInChildLoadingAction(false));
    }
  };
};

const checkInAllChildrenLoadingAction = createAction<boolean>(
  actionTypes.CHECK_IN_ALL_CHILDREN_LOADING,
);
const checkInAllChildrenAction = createAction<ICheckIn[]>(actionTypes.CHECK_IN_ALL_CHILDREN);
export const resetCheckInAllChildrenActionResult = createAction(
  actionTypes.RESET_CHECK_IN_ALL_CHILDREN_ACTION_RESULT,
);

export const checkInAllChildren = (
  clubId: string,
  broughtCustomerId: number,
  serviceInstanceId: string,
  memberIdList: string[],
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(checkInAllChildrenLoadingAction(true));

    try {
      const checkInsResponse = await Services.KidZone.checkInAllChildren(
        clubId,
        broughtCustomerId,
        serviceInstanceId,
        memberIdList,
      );

      const mappedCheckIns = checkInsResponse.map(mapCheckInDto);

      dispatch(checkInAllChildrenAction(mappedCheckIns));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(checkInAllChildrenLoadingAction(false));
    }
  };
};

export const checkOutKid = (personId: number, pickedUpMemberId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(checkInChildLoadingAction(true));

    try {
      const checkInResponse = await Services.KidZone.checkOutKid(personId, pickedUpMemberId);

      const mappedCheckIn = mapCheckInDto(checkInResponse);

      batch(() => {
        dispatch(checkOutKidAction(mappedCheckIn));
        // close check-in profile panel after check-out
        dispatch(setKidZoneMainPanelPersonAction(null));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(checkInChildLoadingAction(false));
    }
  };
};

export const setSelectedCheckIn = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    batch(() => {
      // used for initialize person
      dispatch(setMainPanelPersonAction(personId));
      dispatch(setKidZoneMainPanelPersonAction(personId));
      dispatch(setKidZoneSelectedCheckInAction(personId));
    });
  };
};

export const setChildCheckInPanelId = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    batch(() => {
      // used for initialize person
      dispatch(setMainPanelPersonAction(personId));
      dispatch(setKidZoneRightPanelPersonAction(personId));
    });
  };
};

export const fetchKidProfileInfo = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileInfoLoadingAction(true, personId));

    try {
      const kidProfile = await Services.KidZone.getKidProfile(personId);

      dispatch(fetchProfileInfoAction(kidProfile, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileInfoLoadingAction(false, personId));
    }
  };
};

const fetchGuardianDaycareServicesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_GUARDIAN_DAYCARE_SERVICES_LOADING,
);
const fetchGuardianDaycareServicesAction = createAction<IGuardianDayCareService[]>(
  actionTypes.FETCH_GUARDIAN_DAYCARE_SERVICES,
);
export const resetGuardianDaycareServicesAction = createAction(
  actionTypes.RESET_GUARDIAN_DAYCARE_SERVICES,
);

export const fetchGuardianDaycareServices = (
  personId: number,
  clubId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchGuardianDaycareServicesLoadingAction(true));

    try {
      const services = await Services.KidZone.getGuardianDaycareServices(personId, clubId);

      dispatch(fetchGuardianDaycareServicesAction(services));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchGuardianDaycareServicesLoadingAction(false));
    }
  };
};

const addDateOfBirthActionLoading = createAction<boolean>(
  actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION_LOADING,
);
const addDateOfBirthAction = createAction<{
  updatedPerson: IKidZoneAutocompleteCustomer;
  cardIndex: number;
  personId: number;
}>(actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION);
const addDateOfBirthActionResult = createAction<ActionResult>(
  actionTypes.ADD_DATE_OF_BIRTHDAY_ACTION_RESULT,
);
export const resetAddDateOfBirthActionResult = createAction(
  actionTypes.RESET_ADD_DATE_OF_BIRTHDAY_ACTION_RESULT,
);

export const addDateOfBirth = (
  personId: number,
  cardIndex: number | null,
  date: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addDateOfBirthActionLoading(true));

    try {
      const updatedPerson = await Services.KidZone.addDateOfBirth(personId, date);

      if (cardIndex) {
        dispatch(addDateOfBirthAction({ updatedPerson, cardIndex, personId }));
      }

      dispatch(addDateOfBirthActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addDateOfBirthActionLoading(false));
    }
  };
};

const fetchMembersToCheckInByBarcodeLoading = createAction<boolean>(
  actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_LOADING,
);
const fetchMembersToCheckInByBarcode = createAction<IKidZoneBarcodeCheckinDto>(
  actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE,
);
const fetchMembersToCheckInByBarcodeError = createAction<IFoundMembersToCheckinByBarcodeError>(
  actionTypes.FETCH_MEMBERS_TO_CHECK_IN_BY_BARCODE_ERROR,
);
export const resetMembersToCheckInByBarcode = createAction(
  actionTypes.RESET_MEMBERS_TO_CHECK_IN_BY_BARCODE,
);

export const fetchMembersToCheckInByBarcodeThunk = (barcode: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(fetchMembersToCheckInByBarcodeLoading(true));

      const members = await Services.KidZone.getMembersByBarcode(barcode);

      dispatch(fetchMembersToCheckInByBarcode(members));
    } catch (error) {
      dispatch(fetchMembersToCheckInByBarcodeError({ error, barcode }));
    } finally {
      dispatch(fetchMembersToCheckInByBarcodeLoading(false));
    }
  };
};
