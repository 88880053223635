import React, { useEffect, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// constants
import {
  getPolicyLevel,
  permissionLevelsArray,
  permissionsArray,
  PermissionsGroupsLabels,
} from 'modules/permissions/constants';
import { PermissionLevels } from 'common/constants/permissions';
// interfaces
import { IPolicyFormData, IPolicyListItem } from 'modules/permissions/interfaces/permissions';
import { IActionRequestStatusImt } from 'common/state/interfaces';
// messages
import messages from '../../messages/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import inputErrors from 'common/messages/inputErrors';
// state
import * as selectors from '../../state/policies/selectors';
import * as actions from '../../state/policies/actions';
import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';
// components
import { DialogComponent, Select } from 'common/components';
import CollapsedComponent from './CollapsedComponent';
import { PolicyLevelSwitcher } from '..';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
import { ISecurityPolicyDtoImt } from 'common/interfaces/permissions';
import isFormDirty from 'common/hooks/isFormDirty';

interface EditPolicyModalProps {
  selectedRow: IPolicyListItem | null;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: IPolicyFormData) => void;
  success: boolean | undefined;
}

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required(() => inputErrors.requiredFieldError),
  permissionGroup: yup
    .mixed()
    .notOneOf(['', null], () => inputErrors.requiredFieldError)
    .required(() => inputErrors.requiredFieldError),
  permissionSet: yup
    .array()
    .nullable()
    .min(1, () => inputErrors.permissionSetLengthError),
});

const defaultValues = { title: '', permissionGroup: '', permissionSet: [] };

export default function EditPolicyModal({
  selectedRow,
  isOpen,
  success,
  onClose,
  onSubmit,
  isLoading,
}: EditPolicyModalProps): JSX.Element {
  const currentUserPermissionLevel = useRootSelector(selectCurrentUserSelectedLevel);
  const renderIntlMessage = useRenderIntlMessage();
  const [selectedLevel, setSelectedLevel] = useState<PermissionLevels>(currentUserPermissionLevel);

  // form
  const dispatch = useAppDispatch();
  const policyItem: ISecurityPolicyDtoImt = useRootSelector(selectors.selectPolicyItemData);
  const policyItemActionRequestState: IActionRequestStatusImt = useRootSelector(
    selectors.selectPolicyItemRequestState,
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    formState,
  } = useForm<IPolicyFormData>({
    defaultValues,
    resolver: yupResolver(validationSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'onBlur',
  });

  const isDirty = isFormDirty(formState);
  const selectedPermissionsGroup: string = useWatch({ control, name: 'permissionGroup' });

  const onSubmitHandle = data => {
    onSubmit(data);
  };

  const handleDialogClose = () => {
    reset(defaultValues);
    dispatch(actions.resetPolicyItemData());
    onClose();
  };

  const handlePolicyLevelChange = (level: PermissionLevels) => {
    setSelectedLevel(level);
    setValue('permissionSet', []);
    setValue('permissionGroup', '');
  };

  useEffect(() => {
    if (policyItem.get('id') && isOpen) {
      reset(policyItem.toJS());
      setSelectedLevel(getPolicyLevel(policyItem.get('permissionGroup')));
    }
  }, [reset, policyItem, isOpen]);

  useEffect(() => {
    if (success) reset(defaultValues);
  }, [reset, success]);

  useEffect(() => {
    if (selectedRow?.id && isOpen) dispatch(actions.fetchPolicyItem(selectedRow.id));
  }, [dispatch, selectedRow, isOpen]);

  const permissionGroups = permissionLevelsArray[selectedLevel];
  const permissions = permissionsArray[selectedLevel];

  return (
    <DialogComponent
      title={
        selectedRow ? (
          <FormattedMessage
            {...messages.editPolicyItemTitle}
            values={{ title: selectedRow.title }}
          />
        ) : (
          <FormattedMessage {...messages.newPolicyItemTitle} />
        )
      }
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      size="xs"
      isOpen={isOpen}
      onClose={handleDialogClose}
      onSubmit={handleSubmit(onSubmitHandle)}
      disableFullScreenBehavior
      loading={isLoading}
      isDirty={isDirty}
      actionSuccess={policyItemActionRequestState.getIn(['result', 'success'])}
      keepMounted
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            defaultValue={defaultValues.title}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="none"
                onChange={field.onChange}
                onBlur={field.onBlur}
                label={<FormattedMessage {...inputLabels.name} />}
                value={field.value}
                error={!!errors.title}
                helperText={renderIntlMessage(errors?.title?.message)}
              />
            )}
          />
        </Grid>
        {selectedLevel !== PermissionLevels.PEAK && (
          <Grid item xs={12}>
            <PolicyLevelSwitcher
              isDisabled={!!selectedRow}
              onChange={handlePolicyLevelChange}
              value={selectedLevel}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="permissionGroup"
            control={control}
            render={({ field }) => (
              <Select
                fullWidth
                label={<FormattedMessage {...inputLabels.policyType} />}
                renderValue={(v: string) => PermissionsGroupsLabels[v]}
                variant="outlined"
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!errors.permissionGroup}
                helperText={renderIntlMessage(errors?.permissionGroup?.message)}
                disabled={!!selectedRow}
              >
                {(Object.keys(permissionGroups) as Array<keyof typeof permissionGroups>).map(
                  key => (
                    <MenuItem key={permissionGroups[key]} value={permissionGroups[key]}>
                      {PermissionsGroupsLabels[permissionGroups[key]]}
                    </MenuItem>
                  ),
                )}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedPermissionsGroup && (
            <Controller
              name="permissionSet"
              control={control}
              render={({ field }) => (
                <CollapsedComponent
                  isCheckable
                  permissions={permissions[selectedPermissionsGroup]}
                  permissionSet={field.value}
                  onChange={field.onChange}
                  error={!!errors.permissionSet}
                  helperText={renderIntlMessage((errors?.permissionSet as any)?.message)}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </DialogComponent>
  );
}
