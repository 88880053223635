import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import messages from 'common/messages/messages';
import profileMessages from '../../messages';
import notesMessages from '../../components/Notes/messages/notes';
import { DialogComponent, NumberTextField, TextArea } from 'common/components/index';
import { Controller, useForm } from 'react-hook-form';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { Box } from '@material-ui/core';
import { IFormReward, IReward } from '../../interfaces';
import { useDispatch } from 'react-redux';
import {
  resetNoteCodes,
  searchNoteCodesThunk,
  updatePersonRewardPoints,
} from '../../state/actions';
import { usePersonSelectorTemplate } from '../../hooks/usePersonSelector';
import {
  selectIsUpdateRewardsLoading,
  selectNoteCodes,
  selectNoteCodesLoading,
} from '../../state/selectors';
import { getRewardValidationSchema } from '../../utils';
import IntlService from 'services/intl/IntlService';
import { PeakModules } from 'common/constants/peakModules';
import NoteCodeSelector from 'common/components/NoteCodeSelector/NoteCodeSelector';

interface ITopUpRewardsModal {
  isOpen: boolean;
  onClose: () => void;
  personId: number;
  personRewards: number;
  module: PeakModules;
}

const TopUpRewardsModal: FC<ITopUpRewardsModal> = ({
  isOpen,
  onClose,
  personId,
  personRewards,
  module,
}) => {
  const renderIntlMessage = useRenderIntlMessage();
  const dispatch = useDispatch();
  const usePersonSelector = usePersonSelectorTemplate(personId);
  const isUpdateRewardsLoading: boolean = usePersonSelector(selectIsUpdateRewardsLoading);
  const noteCodesIsLoading = usePersonSelector(selectNoteCodesLoading);
  const noteCodes = usePersonSelector(selectNoteCodes);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormReward>({
    defaultValues: {
      noteText: '',
      points: Number(personRewards),
      noteCode: null,
    },
    resolver: yupResolver(getRewardValidationSchema()),
    mode: 'onSubmit',
  });

  const onSubmit = (formReward: IFormReward) => {
    const reward: IReward = {
      points: formReward.points,
      note: {
        text: formReward.noteText,
        noteCodeId: formReward.noteCode.id,
      },
    };

    dispatch(updatePersonRewardPoints(personId, reward, onClose, module));
  };

  const clearSearchResults = () => {
    dispatch(resetNoteCodes(null, personId));
  };

  const searchNoteCodes = (search: string) => {
    dispatch(searchNoteCodesThunk(search, personId));
  };

  return (
    <DialogComponent
      size="xs"
      title={IntlService.formatMessage(profileMessages.rewardsTitle)}
      submitBtnTitle={IntlService.formatMessage(messages.saveBtn)}
      isOpen={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      disableFullScreenBehavior
      loading={isUpdateRewardsLoading}
      disabled={isUpdateRewardsLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="points"
          render={({ field }) => (
            <NumberTextField
              value={field.value}
              onChange={v => field.onChange(v || 0)}
              variant="outlined"
              onBlur={field.onBlur}
              fullWidth
              label={IntlService.formatMessage(profileMessages.amountRewardsLabel)}
              numberFormatProps={{
                ...defaultNumberFormatProps,
                maxLength: 11,
              }}
              error={!!errors.points}
              helperText={renderIntlMessage(errors.points?.message)}
            />
          )}
        />
        <Box mt={2}>
          <Controller
            name="noteText"
            control={control}
            render={({ field }) => (
              <TextArea
                value={field.value}
                label={IntlService.formatMessage(notesMessages.note)}
                variant="outlined"
                autoComplete="none"
                fullWidth
                minRows={1}
                maxRows={6}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!errors.noteText}
                helperText={renderIntlMessage(errors?.noteText?.message)}
                maxSymbols={255}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="noteCode"
            render={({ field: { value, onChange } }) => {
              return (
                <NoteCodeSelector
                  searchNoteCodes={searchNoteCodes}
                  profileId={personId}
                  onChange={v => onChange(v)}
                  value={value}
                  clearSearchResults={clearSearchResults}
                  noteCodes={noteCodes.toJS()}
                  error={!!errors.noteCode}
                  helperText={renderIntlMessage(errors.noteCode?.message)}
                  isLoading={noteCodesIsLoading}
                />
              );
            }}
          />
        </Box>
      </form>
    </DialogComponent>
  );
};

export default TopUpRewardsModal;
