import React, { useEffect, useMemo, useState } from 'react';
import useRootSelector from 'common/hooks/useRootSelector';
import { FormattedMessage } from 'react-intl';
import { Box, Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import isNumber from 'lodash/isNumber';
// interfaces
import {
  CartUnitTypes,
  IInvoiceDetailsDto,
  IInvoiceDetailsImt,
  ISearchPackagesResultImt,
  MenuItemTypes,
} from 'common/interfaces/invoices';
// state
import * as actions from 'common/state/invoice/actions';
import * as selectors from 'common/state/invoice/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// components
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import SearchInputInvoice from './SearchInput';
import { LoadingBackdrop } from 'common/components';
import { ServiceConfigureModal } from 'common/components/InvoiceOperating/InvoiceModals';
import { PeakModules } from 'common/constants/peakModules';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import invoiceOperatingMessages from '../../messages';
import { useSearchInvoiceProductsState } from 'common/createContext/searchInvoiceProductsContext';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import Services from 'services/network';
import {
  IEditablePackageConfiguration,
  InvoicePackageInstanceUpdateDto,
  IPackageConfiguration,
} from 'common/interfaces/membership';
import { FrequencyType, FrequencyTypes } from 'modules/services/constants/packages';
import { CustomTheme } from 'common/ui/interfaces';
import { RedeemType } from 'common/constants/service';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { DurationUnit, DurationUnits } from 'common/interfaces/common';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { deductTaxAmount } from 'modules/services/utils/billingUtils';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import SignContractService from 'services/application/SignContractService';

export const transformMembership = (
  values: IEditablePackageConfiguration,
): InvoicePackageInstanceUpdateDto => {
  const {
    downPayment,
    durationEditableNumber,
    dayTimeAvailDtoList,
    paymentGrace,
    splitting,
    paymentEditableSchedule,
    services,
    freeze,
    feeSection: { fees },
    pricePerBilling,
  } = values;

  const { ...rest } = values;

  const totalTax = values.revenueCode?.totalTax;

  const paymentSchedule: FrequencyType =
    paymentEditableSchedule?.value &&
    FrequencyType[
      Object.keys(FrequencyType).find(key => FrequencyType[key] === paymentEditableSchedule?.value)
    ];

  const amountToPay = splitting.allow ? splitting.paymentEditableSplits[0].value : pricePerBilling;

  return {
    ...rest,
    menuItemType: MenuItemTypes.Service,
    paymentSchedule,
    durationNumber: durationEditableNumber?.value,
    dayTimeAvails: dayTimeAvailDtoList,
    hasCustomDayTimeAvailability: !!dayTimeAvailDtoList?.length,

    paymentSplitEnabled: !!splitting && splitting.allow,
    paymentNumber: splitting?.paymentEditableNumber?.value,
    paymentSplits: splitting?.paymentEditableSplits,

    downPaymentEnabled: !!downPayment && downPayment.allow,
    downPaymentNumber: downPayment?.editableNumber?.value,
    downPaymentAmount: downPayment?.editableAmount?.value,
    downPaymentSplits: downPayment?.editableSplits,

    paymentGraceEnabled: !!paymentGrace && paymentGrace.allow,
    paymentGraceNumber: paymentGrace?.editableDurationNumber?.value,

    freezeDaysNumber: freeze?.editableDaysNumber?.value ?? null,
    taxAmount: deductTaxAmount(amountToPay || pricePerBilling, totalTax),
    amountToPay,

    services: services
      .filter(el => el.included)
      .map(service => ({
        ...service,
        ...(service.package && { package: transformMembership(service.package) }),
      })),

    fees: fees
      ?.filter(el => el.included)
      .map(fee => ({
        id: fee.id,
        totalAmount: fee.totalAmount?.value,
        chargeAfterDays: fee.chargeAfterDays?.value,
        paymentNumber: fee.split ? fee.paymentsNumber?.value : null,
        paymentSplits: fee.split ? fee.editableSplits : null,
        fee: fee.fee,
        included: fee.included,
      })),
    outOfTermBillingOption: {
      ...rest.outOfTermBillingOption,
      billingOption: rest.outOfTermBillingOption?.id,
    },
  };
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  searchResults: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    marginRight: theme.spacing(-2),
    paddingRight: theme.spacing(2),
  },
  packageItem: {
    borderTop: `1px solid ${theme.palette.borderColor?.main}`,
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  limitedRedeemLabel: {
    textTransform: 'lowercase',
  },
  comingSoonLabel: {
    color: theme.palette.secondary.dark,
  },
  configureBtn: {
    minWidth: 'initial',
  },
}));

interface ISearchServicePackagesProps {
  module: PeakModules;
  isPaymentStep?: boolean;
}

export default function SearchServicePackages({
  module,
  isPaymentStep,
}: ISearchServicePackagesProps): JSX.Element {
  // state
  const dispatch = useAppDispatch();

  const searchPackagesData: ISearchPackagesResultImt = useRootSelector(
    selectors.selectSearchPackagesData,
  );

  const employeeClubId = useRootSelector(selectUserSelectedClubId);

  const isEmptyData =
    !searchPackagesData.get('available')?.size && !searchPackagesData.get('comingSoon')?.size;
  const isSearchPackagesLoading = useRootSelector(selectors.selectSearchPackagesLoading);
  const isSearchInventoryLoading = useRootSelector(selectors.selectSearchInventoryLoading);
  const invoice: IInvoiceDetailsImt = useRootSelector(selectors.selectCurrentInvoice);

  const isSearchLoading = isSearchPackagesLoading || isSearchInventoryLoading;
  const [configureServiceId, setConfigureServiceId] = useState(null);
  const [configureService, setConfigureService] = useState<IPackageConfiguration | null>(null);

  const { toggleShowAddServicePackages } = useSearchInvoiceProductsState();

  const renderIntlMessage = useRenderIntlMessage();

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const transformedInvoice = useMemo(() => invoice?.toJS(), [invoice]);

  useEffect(() => {
    if (transformedInvoice.salesperson.id) {
      dispatch(
        actions.searchClubServicePackages({
          module,
          employeeClubId,
          isPaymentStep,
          searchStr: null,
          salesPersonId: transformedInvoice.salesperson.id,
        }),
      );
    }
  }, [dispatch, employeeClubId, isPaymentStep, module, transformedInvoice.salesperson.id]);

  const fetchConfigurationForService = (packageServiceId: string) => {
    setConfigureServiceId(packageServiceId);
    let fetchMembershipConfiguration = null;
    switch (module) {
      case PeakModules.FrontDesk:
        fetchMembershipConfiguration = isPaymentStep
          ? Services.FrontDesk.getMembershipConfigurationCustomerPos
          : Services.FrontDesk.getMembershipConfigurationPos;
        break;
      case PeakModules.Members:
        fetchMembershipConfiguration = Services.Members.getMembershipConfigurationPos;
        break;
      case PeakModules.PersonalTrainingCrm:
        fetchMembershipConfiguration = Services.PTLeads.getPackageConfigurationPos;
        break;
      case PeakModules.Crm:
        fetchMembershipConfiguration = Services.Leads.getMembershipConfigurationPos;
        break;
      case PeakModules.PosKiosk:
        fetchMembershipConfiguration = Services.PosKiosk.getMembershipConfiguration;
        break;
      default:
    }

    if (fetchMembershipConfiguration) {
      fetchMembershipConfiguration(packageServiceId, employeeClubId).then(response =>
        setConfigureService(response),
      );
    }
  };

  const onSearchProductsStringChange = (searchStr: string) => {
    if (transformedInvoice.salesperson.id) {
      dispatch(
        actions.searchClubServicePackages({
          module,
          employeeClubId,
          isPaymentStep,
          searchStr,
          salesPersonId: transformedInvoice.salesperson.id,
        }),
      );
    } else {
      dispatch(actions.resetSearchPackagesResult());
    }
  };

  const handleAddService = serviceData => {
    const onSuccess = (invoiceUnitDetails: IInvoiceDetailsDto): void => {
      const { invoiceUnits = [] } = invoiceUnitDetails;
      const packageInstanceIds: number[] = [];

      for (const invoiceUnit of invoiceUnits) {
        if (invoiceUnit.bundle && isNumber(invoiceUnit.bundle.instanceId)) {
          packageInstanceIds.push(invoiceUnit.bundle.instanceId);
        }
      }

      SignContractService.setPackageInstanceIds(packageInstanceIds);
    };

    dispatch(
      actions.addInvoiceUnit(
        transformedInvoice.id,
        {
          servicePackages: [
            {
              ...transformMembership({
                ...configureService,
                ...serviceData.packageConfiguration,
              }),
              id: null,
              packageTemplateId: configureServiceId,
              title: configureService?.title,
              inventories: configureService?.inventories,
              menuItemType: MenuItemTypes.Service,
            },
          ],
          memberId: transformedInvoice.customer?.id ?? null,
        },
        CartUnitTypes.SERVICE_BUNDLE,
        module,
        isPaymentStep,
        false,
        onSuccess,
      ),
    );

    toggleShowAddServicePackages(false);
  };

  const deleteService = (invoiceUnitId: string) => {
    dispatch(
      actions.deleteInvoiceUnit(transformedInvoice.id, invoiceUnitId, module, isPaymentStep),
    );
  };

  const getLimitedRedeemLabel = (
    type: RedeemType,
    limitedRedeemNumber: number,
    durationUnit: DurationUnit,
  ) => {
    switch (type) {
      case RedeemType.Session:
        return `${renderIntlMessage(commonMessages.sessions, { count: limitedRedeemNumber })}`;
      case RedeemType.Duration:
        return `${limitedRedeemNumber} ${renderIntlMessage(DurationUnits.message(durationUnit))}`;
      default:
        return limitedRedeemNumber;
    }
  };

  return (
    <Box display="flex" flexDirection="column" flex="1" height="100%" pt={1}>
      <SearchInputInvoice
        placeholder={inputLabels.searchServices}
        callback={onSearchProductsStringChange}
      />

      <Box className={classes.searchResults}>
        {!!searchPackagesData.get('available')?.size && (
          <Box>
            {searchPackagesData.get('available')?.map(productItem => {
              const currentItem = transformedInvoice.invoiceUnits?.find(
                item =>
                  item.type === CartUnitTypes.SERVICE_BUNDLE &&
                  item.bundle.id === productItem.get('id'),
              );
              const isInCart = !!currentItem;

              return (
                <Box paddingY={1.5} className={classes.packageItem}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" component="p">
                      {productItem.get('title')}
                    </Typography>

                    <Box display="flex" alignItems="center">
                      {!productItem.get('availableToSellByCurrentEmployee') && (
                        <Tooltip
                          title={
                            <FormattedMessage
                              {...invoiceOperatingMessages.doNotHavePermissionsToSell}
                            />
                          }
                        >
                          <WarningIcon className={classes.warningIcon} />
                        </Tooltip>
                      )}

                      <Button
                        variant={isInCart ? 'text' : 'contained'}
                        color={isInCart ? 'default' : 'primary'}
                        size="small"
                        className={classes.configureBtn}
                        onClick={() =>
                          isInCart
                            ? deleteService(currentItem.id)
                            : fetchConfigurationForService(productItem.get('id'))
                        }
                      >
                        {isInCart ? (
                          <FormattedMessage {...commonMessages.removeBtn} />
                        ) : (
                          <FormattedMessage {...commonMessages.configureBtn} />
                        )}
                      </Button>
                    </Box>
                  </Box>

                  {productItem.get('packageServices').map(servicePackage => (
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt={1.5}>
                      <Typography>{servicePackage.get('title')}</Typography>

                      <Typography className={classes.limitedRedeemLabel}>
                        {!servicePackage.get('limited') ? (
                          <FormattedMessage {...commonMessages.unlimitedLabel} />
                        ) : (
                          <>
                            {getLimitedRedeemLabel(
                              servicePackage.get('redeemType'),
                              servicePackage.get('limitedRedeemNumber'),
                              servicePackage.get('redeemDurationUnit'),
                            )}
                          </>
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}

        {!!searchPackagesData.get('comingSoon')?.size && (
          <Box>
            <Box paddingY={1.5} className={classes.packageItem}>
              <Typography variant="button" className={classes.comingSoonLabel}>
                <FormattedMessage {...invoiceOperatingMessages.comingSoon} />
              </Typography>
            </Box>

            {searchPackagesData.get('comingSoon')?.map(productItem => (
              <Box
                display="flex"
                flexDirection="column"
                paddingY={1.5}
                className={classes.packageItem}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="h5" component="p">
                    {productItem.get('title')}
                  </Typography>

                  <Typography className={classes.comingSoonLabel}>
                    {timezoneMoment(productItem.get('startDate')).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                </Box>

                {!!productItem.getIn(['defaultBillingOption']) && (
                  <Typography component="p">
                    <Typography component="span">
                      {`$${productItem.getIn(['defaultBillingOption', 'totalAmount'])?.toFixed(2) ??
                        '-'}/`}
                    </Typography>
                    <Typography component="span">
                      {FrequencyTypes.translate(
                        productItem.getIn([
                          'defaultBillingOption',
                          'paymentEditableSchedule',
                          'value',
                        ]),
                      )}
                    </Typography>
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        {!isSearchLoading && isEmptyData && (
          <Box display="flex" flex="1" alignItems="center" justifyContent="center">
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage {...commonMessages.noItemsFound} />
            </Typography>
          </Box>
        )}

        {isSearchLoading && <LoadingBackdrop isLoading />}
      </Box>

      <ServiceConfigureModal
        module={module}
        onSubmit={handleAddService}
        onClose={() => setConfigureService(null)}
        configureService={configureService}
      />
    </Box>
  );
}
