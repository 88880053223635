import React, { useCallback, useState } from 'react';
import { UseFormReturn, FormProvider, useFieldArray } from 'react-hook-form';
import { Box, CardContent, Divider, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// interfaces
import {
  IFormStepStyle,
  IncludedServicePackageType,
  IPackageService,
  IServicesSection,
} from 'modules/services/interfaces/packages';
// components
import { IDayTimeAvailabilityDto } from 'common/interfaces/common';
import ServicesAndPackagesSearch from './ServicesAndPackagesSearch/ServicesAndPackagesSearch';
import IncludedServiceItem from './IncludedServiceItem/IncludedServiceItem';
import IncludedPackageItem from './IncludedPackageItem/IncludedPackageItem';
import { IncludedPackageBillingType, LimitationType } from 'modules/services/constants/packages';
import PackageInfoDialog from './PackageInfoDialog/PackageInfoDialog';
import ViewBillingOptionModal from 'modules/services/components/EditPackageForm/ServicesStep/ViewBillingOptionModal/ViewBillingOptionModal';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  durationEditableWrap: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      padding: 0,
    },
    '& >div .MuiOutlinedInput-root': {
      width: 'calc(100% + 1px)',
    },
    '& >div:last-child .MuiOutlinedInput-root': {
      width: '100%',
    },
  },
}));

interface IServicesStepFormProps extends IFormStepStyle {
  formMethods: UseFormReturn<IServicesSection>;
  validAvailabilityRange?: IDayTimeAvailabilityDto[];
  packageId?: string;
}

const ServicesStepForm = ({
  formMethods,
  smallPaddingContent,
  validAvailabilityRange,
  packageId,
}: IServicesStepFormProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const [isPackageInfoOpen, setIsPackageInfoOpen] = useState<boolean>(false);
  const [selectedBillingOptionId, setSelectedBillingOptionId] = useState<string | null>(null);
  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null);

  const {
    control,
    watch,
    formState: { errors },
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'includedServices',
    keyName: 'includedServiceKey',
  });

  // handlers

  const handleOnInclude = (option: IPackageService, type: IncludedServicePackageType): void => {
    const isAlreadyAdded =
      fields.findIndex(field => {
        if (type === IncludedServicePackageType.Service) {
          return field.service?.id === option.id;
        }
        return field.package?.id === option.id;
      }) >= 0;

    if (isAlreadyAdded) return;

    if (type === IncludedServicePackageType.Service) {
      append({
        id: option.id,
        service: option,
        package: null,
        excludable: false,
        limited: false,
        limitationType: LimitationType.Unlimited,
        hasCustomDayTimeAvailability: false,
        dayTimeAvailDtoList: [],
      });
    } else {
      append({
        id: option.id,
        service: null,
        package: option,
        billingAmount: 0,
        billingType: IncludedPackageBillingType.Free,
        billingOption: null,
      } as any); // TODO - PRM-1810 need types
    }
  };

  const handleOpenPackageInfoModal = useCallback((packId: string) => {
    setIsPackageInfoOpen(true);
    setSelectedPackageId(packId);
  }, []);

  const handleClosePackageInfoModal = useCallback(() => {
    setIsPackageInfoOpen(false);
    setSelectedPackageId(null);
  }, []);

  const handleOpenBillingOptionViewModal = useCallback(
    (billingOptionId: string, packId: string) => {
      setSelectedPackageId(packId);
      setSelectedBillingOptionId(billingOptionId);
    },
    [],
  );

  const handleCloseBillingOptionViewModal = useCallback(() => {
    setSelectedBillingOptionId(null);
  }, []);

  const servicePackageList: string[] = [];

  for (const field of fields) {
    if (field.package && field.package.id) {
      servicePackageList.push(field.package.id);
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Box className={classes.contentWrapper}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <ServicesAndPackagesSearch
                    packageId={packageId}
                    servicePackageList={servicePackageList}
                    onInclude={handleOnInclude}
                  />
                </Grid>

                {fields.map((field, index) => (
                  <>
                    <Grid item xs={12}>
                      {field.service && (
                        <IncludedServiceItem
                          key={field.includedServiceKey}
                          index={index}
                          field={field}
                          control={control}
                          errors={errors?.includedServices?.[index]}
                          watch={watch}
                          onRemove={remove}
                          validAvailabilityRange={validAvailabilityRange}
                        />
                      )}

                      {field.package && (
                        <IncludedPackageItem
                          key={field.includedServiceKey}
                          index={index}
                          field={field}
                          onRemove={remove}
                          onDetailsClick={handleOpenPackageInfoModal}
                          onSelectBillingOption={handleOpenBillingOptionViewModal}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Grid>

            <PackageInfoDialog
              open={isPackageInfoOpen}
              packageId={selectedPackageId}
              onClose={handleClosePackageInfoModal}
            />

            {!!selectedBillingOptionId && selectedPackageId && (
              <ViewBillingOptionModal
                isOpen={!!selectedBillingOptionId}
                onClose={handleCloseBillingOptionViewModal}
                packageId={selectedPackageId}
                billingOptionId={selectedBillingOptionId}
              />
            )}
          </Grid>
        </CardContent>
      </Box>
    </FormProvider>
  );
};

export default React.memo(ServicesStepForm);
