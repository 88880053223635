// libraries
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { getStepStyles } from '../styleConstants';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { IInvoicePaymentSplit, PaymentsType } from 'common/interfaces/invoices';
import Button from '../../../../Button/Button';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../../../messages/messages';
import { getCreditCardIcon } from '../../../../CreditCardData/constants';
import { Add as AddIcon } from '@material-ui/icons';
import CreditCardData from '../../../../CreditCardData/CreditCardData';
import messages from '../../../messages';
import { DeviceActionButton, InfoTooltip, LoadingBackdrop, PaymentField } from 'common/components';
import { defaultPriceNumberProps } from '../../../../NumberController/NumberController';
import * as actions from 'common/state/invoice/actions';
import { resetUpdateInvoiceWithSync, updateInvoiceWithSync } from 'common/state/invoice/actions';
import inputLabels from '../../../../../messages/inputLabels';
import { PeakModules } from 'common/constants/peakModules';
import {
  selectAddCreditCardLoading,
  selectAddCreditCardResult,
  selectCurrentInvoice,
  selectStoredCreditCardsLoading,
  selectStoredCreditCardsResult,
  selectUpdatedInvoiceLoading,
} from 'common/state/invoice/selectors';
import { CreditCardTypes, IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { ActionResult } from 'common/constants';
import { PaymentOptionType } from 'common/components/InvoiceOperating/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getExpiryDate } from 'common/utils/time';
import { StepContext } from 'common/createContext/stepContext';
import { useUpdatePaymentData } from 'common/components/InvoiceOperating/InvoicePaymentWizard/steps/useUpdatePaymentData';
import {
  selectCurrentPaymentProcessor,
  selectCurrentUserCorporation,
  selectIsProcessorSettingsConfigured,
} from '../../../../../../modules/authentication/state/selectors';
import { BankCode } from '../../../../../../modules/corporate-settings/interfaces';
import TokenizeCreditCard from '../../../../TokenizeCreditCard/TokenizeCreditCard';
import { snackbar } from '../../../../../utils/snackbarUtils';
import Alert from '../../../../Alert/Alert';
import { AlertTypes } from '../../../../../interfaces/alerts';
import { DeviceType } from '../../../../../constants/scanner';
import useRootSelector from 'common/hooks/useRootSelector';

interface ICreditCardPaymentStepProps {
  memberId?: number;
  leftToPay: number;
  clubId: string;
  creditCardType: PaymentsType;

  module: PeakModules;
  isPaymentStep?: boolean;
  paymentTypeId?: string;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  ...getStepStyles(theme),
  cardNumber: {
    margin: theme.spacing(0, 1, 0, 1.5),
  },
  radioLabel: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  radio: {
    '&:hover': {
      boxShadow: 'none',
      borderRadius: '50%',
    },
  },
  cardListWrapper: {
    paddingLeft: theme.spacing(3),
  },
}));

interface IPaymentOption {
  type: PaymentOptionType;
  id?: string;
  lastFour?: string;
  creditCardType?: CreditCardTypes;
  expired?: boolean;
  cardNumber?: string;
  expDate?: string;
}

export interface IMagneticStripeCreditCardData {
  nameOnCard: string;
  cardNumber: string;
  securityCode: string;
  expMonth: string;
  expYear: string;
}

const defaultPaymentOptions: IPaymentOption[] = [
  { type: PaymentOptionType.PaymentTerminal },
  { type: PaymentOptionType.StoredCard },
  { type: PaymentOptionType.ImmediateCreditCardPayment },
];

const CreditCardPaymentStep: React.FC<ICreditCardPaymentStepProps> = (
  props: ICreditCardPaymentStepProps,
): JSX.Element => {
  const classes = useStyles(props);
  const {
    memberId,
    creditCardType,
    isPaymentStep,
    leftToPay,
    onClose,
    paymentTypeId,
    module,
    clubId,
  } = props;

  // local state
  const [isAddNewCardOpen, setIsAddNewCardOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IPaymentOption>(defaultPaymentOptions[0]);
  const [selectedStoredCard, setSelectedStoredCard] = useState(null);
  const [amountToPay, setAmountToPay] = useState<string | number>(leftToPay);
  const [immediateCardData, setImmediateCardData] = useState<IAddCreditCard | null>(null);
  const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);
  const [cardDataFromMagneticStripe, setCardDataFromMagneticStripe] = useState<
    IMagneticStripeCreditCardData
  >(null);
  const [storedCreditCards, setStoredCreditCards] = useState([]);

  // global state
  const dispatch = useDispatch();

  const storedCreditCardsResult = useRootSelector(selectStoredCreditCardsResult);
  const storedCreditCardsLoading = useRootSelector(selectStoredCreditCardsLoading);
  const addCreditCardLoading = useRootSelector(selectAddCreditCardLoading);
  const addCreditCardResult = useRootSelector(selectAddCreditCardResult);
  const currentInvoice = useRootSelector(selectCurrentInvoice);
  const isUpdatingInvoiceLoading = useRootSelector(selectUpdatedInvoiceLoading);
  const isProcessorSettingsConfigured: boolean = useRootSelector(
    selectIsProcessorSettingsConfigured,
  );
  const paymentProcessorType = useRootSelector(selectCurrentPaymentProcessor);
  const currentCorporationId = useRootSelector(selectCurrentUserCorporation)?.get('id');

  const { helperData } = useContext(StepContext);
  const { profileId } = helperData || {};

  const renderIntlMessage = useRenderIntlMessage();

  const isImmediateCreditCardOption =
    selectedOption.type === PaymentOptionType.ImmediateCreditCardPayment;
  const isStoredCreditCardOption = selectedOption.type === PaymentOptionType.StoredCard;
  const isItransactPaymentProcessorType = paymentProcessorType === BankCode.ITRANSACT;

  // handlers

  const transformImmediateCardData = () => {
    const { expiredIn, ...otherData } = immediateCardData;
    const expireSplit: string[] = expiredIn.split('/');

    return {
      ...otherData,
      number: otherData.cardNumber.replaceAll(' ', ''),
      expirationMonth: expireSplit[0],
      expirationYear: expireSplit[1],
    };
  };

  const handleProceed = (): void => {
    const invoiceData = currentInvoice.toJS();
    const invoicePaymentSplit: IInvoicePaymentSplit = {
      paymentMethodId: paymentTypeId,
      type: creditCardType,
      paymentAmount: amountToPay,
      creditCardPaymentOptionType: selectedOption.type,
      paymentAccountId:
        selectedOption.type === PaymentOptionType.StoredCard ? selectedStoredCard.id : undefined,
      ...(!memberId &&
        isImmediateCreditCardOption && {
          creditCard: transformImmediateCardData(),
        }),
    };

    dispatch(
      updateInvoiceWithSync(
        module,
        clubId,
        invoiceData,
        invoicePaymentSplit,
        profileId,
        isPaymentStep,
      ),
    );
  };

  const handleSubmitTokenize = (data: any): void => {
    switch (paymentProcessorType) {
      case BankCode.COMMERCEHUB:
        dispatch(actions.tokenizeCommerceHubEncryptedCard(memberId, data));
        break;
      case BankCode.ITRANSACT:
        // we do nothing, because we don't have response for the action.
        // ITransact will trigger backend api endpoint with memberId and xid to tokenize cc
        break;
      default:
        snackbar.warning(<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />);
    }

    setIsIFrameModalOpen(false);
  };

  const handleAddImmediateCard = (data: IAddCreditCard): void => {
    setImmediateCardData(data);
    setIsAddNewCardOpen(false);
  };

  const handleChangePaymentMethodType = (option: IPaymentOption): void => {
    if (option && PaymentOptionType.StoredCard !== option.type) {
      setSelectedStoredCard(null);
    }
    setSelectedOption(option);
  };

  const onScanningSuccess = (scannerStr: string): void => {
    // eslint-disable-next-line no-console
    console.log(scannerStr);
    // TODO handle and parse this scannerStr
    // TODO right now, we will pass test data.
    setCardDataFromMagneticStripe({
      cardNumber: '4111111111111111',
      nameOnCard: 'testName',
      securityCode: '777',
      expYear: '30',
      expMonth: '10',
    });
  };

  // effects
  useEffect(() => {
    dispatch(actions.resetStoredCreditCardsReducer());
  }, [dispatch]);

  useEffect(() => {
    if (memberId) {
      dispatch(actions.fetchStoredCreditCards(module, creditCardType, memberId));
    }
  }, [creditCardType, dispatch, memberId, module]);

  useEffect(() => {
    setStoredCreditCards(
      storedCreditCardsResult.map(card => ({
        type: PaymentOptionType.StoredCard,
        creditCardType: card.get('creditCardType'),
        lastFour: card.get('lastFour'),
        expDate: card.get('expDate'),
        expired: card.get('expired'),
        id: card.get('id'),
      })),
    );
  }, [storedCreditCardsResult]);

  useEffect(() => {
    if (addCreditCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.fetchStoredCreditCards(module, creditCardType, memberId));
      dispatch(actions.resetStoredCreditCardResult());
      setIsAddNewCardOpen(false);
    }
  }, [addCreditCardResult, creditCardType, dispatch, memberId, module]);

  const resetStep = () => {
    dispatch(resetUpdateInvoiceWithSync());
  };

  useUpdatePaymentData(module, profileId, onClose);

  useEffect(() => {
    return resetStep;
    // Should perform reset only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // renders

  return (
    <>
      <Grid container spacing={2} className={classes.body}>
        <Grid item sm={12} xs={12}>
          <FormControl component="fieldset" disabled={isAddNewCardOpen}>
            <RadioGroup aria-label="cardPaymentSelector" name="cardPaymentSelector">
              {defaultPaymentOptions.map(option => (
                <>
                  {option.type === PaymentOptionType.PaymentTerminal && (
                    <FormControlLabel
                      key={PaymentOptionType.PaymentTerminal}
                      className={classes.radioLabel}
                      control={<Radio className={classes.radio} color="primary" />}
                      checked={option === selectedOption}
                      onClick={() => handleChangePaymentMethodType(option)}
                      label={<FormattedMessage {...messages.payViaTerminal} />}
                    />
                  )}

                  {option.type === PaymentOptionType.StoredCard && (
                    <FormControlLabel
                      key={PaymentOptionType.StoredCard}
                      className={classes.radioLabel}
                      control={<Radio className={classes.radio} color="primary" />}
                      checked={option === selectedOption}
                      onClick={() => handleChangePaymentMethodType(option)}
                      label={<FormattedMessage {...messages.paymentOptionTypeViaStoreCard} />}
                    />
                  )}

                  {option.type === PaymentOptionType.ImmediateCreditCardPayment && !memberId && (
                    <FormControlLabel
                      key={option.id}
                      value={option}
                      disabled={option.expired}
                      checked={option === selectedOption}
                      onClick={() => !option.expired && handleChangePaymentMethodType(option)}
                      className={classes.radioLabel}
                      control={
                        <Radio
                          className={classes.radio}
                          color="primary"
                          disabled={option.expired}
                        />
                      }
                      label={`${renderIntlMessage(
                        messages.paymentOptionTypeViaImmediateCreditCard,
                      )} ${
                        immediateCardData ? `(**** ${immediateCardData.cardNumber.slice(-4)})` : ''
                      }`}
                    />
                  )}
                </>
              ))}
            </RadioGroup>
          </FormControl>
          <LoadingBackdrop isLoading={addCreditCardLoading || storedCreditCardsLoading} />
        </Grid>

        {isStoredCreditCardOption && (
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.cardListWrapper}>
              <Grid item xs={12}>
                <FormControl component="fieldset" disabled={isAddNewCardOpen}>
                  <RadioGroup aria-label="cardPaymentSelector" name="cardPaymentSelector">
                    {storedCreditCards.map(option => (
                      <FormControlLabel
                        key={option.id}
                        value={option}
                        disabled={option.expired}
                        checked={option === selectedStoredCard}
                        onClick={() => !option.expired && setSelectedStoredCard(option)}
                        className={classes.radioLabel}
                        control={
                          <Radio
                            className={classes.radio}
                            color="primary"
                            disabled={option.expired}
                          />
                        }
                        label={
                          <Box key={option.id} display="flex" alignItems="center">
                            <SvgIcon
                              fontSize="small"
                              component={getCreditCardIcon(option.creditCardType)}
                            />
                            <Typography
                              className={classes.cardNumber}
                              color={option.expired ? 'error' : 'initial'}
                            >
                              {`**** ${option.lastFour} ${getExpiryDate(option.expDate)} ${
                                option.expired ? renderIntlMessage(commonMessages.expired) : ''
                              }`}
                            </Typography>
                          </Box>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {!isProcessorSettingsConfigured ? (
                <Grid item xs={12}>
                  <Alert
                    title={<FormattedMessage {...commonMessages.creditCardBankNotConfigured} />}
                    severity={AlertTypes.Warning}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setIsIFrameModalOpen(true)}
                    >
                      <FormattedMessage {...commonMessages.addCardBtn} />
                    </Button>

                    {isIFrameModalOpen && (
                      <TokenizeCreditCard
                        paymentProcessorType={paymentProcessorType}
                        corporationId={currentCorporationId}
                        memberId={memberId}
                        isOpen={isIFrameModalOpen}
                        presetCreditCard={cardDataFromMagneticStripe}
                        onClose={() => setIsIFrameModalOpen(false)}
                        onSubmit={data => handleSubmitTokenize(data)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <DeviceActionButton
                        disabled={isItransactPaymentProcessorType}
                        deviceType={DeviceType.MagneticStripe}
                        onScanningSuccess={onScanningSuccess}
                      />
                      {isItransactPaymentProcessorType && (
                        <InfoTooltip
                          text={
                            <FormattedMessage
                              {...messages.disabledMagneticStripeForITransactTooltip}
                            />
                          }
                        />
                      )}
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            <LoadingBackdrop isLoading={addCreditCardLoading || storedCreditCardsLoading} />
          </Grid>
        )}

        <Grid item xs={12}>
          {isImmediateCreditCardOption && !immediateCardData && (
            <CreditCardData onCardAdd={handleAddImmediateCard} withScanning />
          )}
        </Grid>

        <Grid item xs={12}>
          <PaymentField
            fullWidth
            variant="outlined"
            defaultValue={leftToPay}
            value={amountToPay}
            onChange={setAmountToPay}
            onBlur={setAmountToPay}
            label={<FormattedMessage {...inputLabels.amountToPay} />}
            numberFormatProps={{
              ...defaultPriceNumberProps,
              max: leftToPay,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.footerActions}>
        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            disabled={
              isUpdatingInvoiceLoading ||
              (isImmediateCreditCardOption && !immediateCardData) ||
              (isStoredCreditCardOption && !selectedStoredCard)
            }
            onClick={handleProceed}
          >
            <FormattedMessage {...commonMessages.proceedBtn} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCardPaymentStep;
