import React, { useCallback, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { batch, useDispatch } from 'react-redux';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';

import { IAllergiesFormValues } from 'modules/members/interfaces';
import { IKidProfileImt } from 'modules/kid-zone/interfaces';
import { IBlocksSectionProps } from 'common/components/PersonProfile/interfaces';
import { ICustomerEmergencyContactDto, TAllergyImtList } from 'common/interfaces/additionalInfo';

import { DictionaryList } from 'common/constants';

import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  fetchAllergiesThunk,
  fetchEmergencyContactThunk,
  resetAllergies,
  resetEmergencyContact,
  updateAllergiesThunk,
  updateEmergencyContactThunk,
} from 'modules/kid-zone/state/actions';

import { KidZoneCheckInsHistory, Notes } from 'common/components/PersonProfile/components';
import { Allergies, AllergiesModal } from 'modules/members/components';

import {
  selectAllergies,
  selectAllergiesIsLoading,
  selectEmergencyContact,
  selectEmergencyContactLoading,
} from 'modules/kid-zone/state/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { PeakModules } from 'common/constants/peakModules';
import EmergencyContact from 'modules/members/components/EmergencyContact/EmergencyContact';
import EmergencyContactModal from 'modules/members/components/EmergencyContactModal/EmergencyContactModal';
import useRootSelector from 'common/hooks/useRootSelector';

const BlocksSection = ({
  profile,
  module,
  isLessThanLaptop,
}: IBlocksSectionProps<IKidProfileImt>): JSX.Element => {
  const dispatch = useDispatch();

  const allergyDictionaryList: TAllergyImtList = useRootSelector(
    selectDictionaryList(DictionaryList.ALLERGIES),
  );
  const selectedAllergies = useRootSelector(selectAllergies);
  const isLoadingAllergies = useRootSelector(selectAllergiesIsLoading);
  const emergencyContact = useRootSelector(selectEmergencyContact);
  const emergencyContactLoading = useRootSelector(selectEmergencyContactLoading);

  const [isOpenAllergiesModal, setIsOpenAllergiesModal] = useState(false);
  const [isOpenEmergencyContactModal, setIsOpenEmergencyContactModal] = useState(false);
  const personId = profile?.get('id');

  useEffect(() => {
    if (profile?.size) {
      if (personId) {
        dispatch(fetchAllergiesThunk(personId));
        dispatch(fetchEmergencyContactThunk(personId));
      }

      dispatch(fetchDictionaryList(DictionaryList.ALLERGIES));
    }
  }, [dispatch, module, profile, personId]);

  useEffect(() => {
    return () => {
      batch(() => {
        dispatch(resetAllergies());
        dispatch(resetEmergencyContact());
        dispatch(resetDictionaryListAction({ dictionary: DictionaryList.ALLERGIES }));
      });
    };
  }, [dispatch]);

  const onOpenAllergiesModal = useCallback(() => {
    setIsOpenAllergiesModal(true);
  }, [setIsOpenAllergiesModal]);

  const onCloseAllergiesModal = useCallback(() => {
    setIsOpenAllergiesModal(false);
  }, [setIsOpenAllergiesModal]);

  const onSubmitAllergiesModal = (values: IAllergiesFormValues) => {
    if (personId) {
      dispatch(updateAllergiesThunk(personId, values));
    }
  };

  const onSubmitEmergencyContactModal = (values: ICustomerEmergencyContactDto) => {
    if (personId) {
      dispatch(updateEmergencyContactThunk(personId, values));
    }
  };

  const onOpenEmergencyContactModal = () => {
    setIsOpenEmergencyContactModal(true);
  };

  const onCloseEmergencyContactModal = useCallback(() => {
    setIsOpenEmergencyContactModal(false);
  }, [setIsOpenEmergencyContactModal]);

  useComponentDidUpdate(() => {
    if (selectedAllergies) {
      onCloseAllergiesModal();
    }
  }, [selectedAllergies, onCloseAllergiesModal]);

  useComponentDidUpdate(() => {
    if (emergencyContact) {
      onCloseEmergencyContactModal();
    }
  }, [emergencyContact, onCloseEmergencyContactModal]);

  return (
    <>
      <Grid item xs={6}>
        <EmergencyContact
          emergencyContact={emergencyContact}
          onOpenModal={onOpenEmergencyContactModal}
        />
      </Grid>
      <Grid item xs={6}>
        <Allergies allergies={selectedAllergies} onOpenModal={onOpenAllergiesModal} />
      </Grid>

      {module ? (
        <Grid item xs={12}>
          <Notes module={module} customerId={Number(personId)} isMobile={!!isLessThanLaptop} />
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <KidZoneCheckInsHistory personId={Number(personId)} module={PeakModules.KidZone} />
      </Grid>

      {isOpenAllergiesModal && (
        <AllergiesModal
          allergies={selectedAllergies}
          allergyDictionaryList={allergyDictionaryList}
          onSubmit={onSubmitAllergiesModal}
          onClose={onCloseAllergiesModal}
          isOpen={isOpenAllergiesModal}
          isLoading={isLoadingAllergies}
        />
      )}
      {isOpenEmergencyContactModal && (
        <EmergencyContactModal
          emergencyContact={emergencyContact}
          onSubmit={onSubmitEmergencyContactModal}
          onClose={onCloseEmergencyContactModal}
          isOpen={isOpenEmergencyContactModal}
          isLoading={emergencyContactLoading}
        />
      )}
    </>
  );
};

export default BlocksSection;
