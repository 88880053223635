import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Description, Email, PictureAsPdf, Print } from '@material-ui/icons';
import { ReactComponent as CSV } from 'img/icons/file-csv.svg';

import { ReactComponent as Export } from 'img/icon-export.svg';

// Interfaces
import { ExportTypes } from 'modules/reports/interfaces/export';

// Components
import SendReportModal from '../SendReportModal/SendReportModal';

interface IExportReportProps {
  exportTypes: ExportTypes[];
  exportAction?: (data: Record<string, unknown>) => void;
  exportResult?: Record<string, boolean>;
}

const ExportReport = ({
  exportAction,
  exportResult,
  exportTypes,
}: IExportReportProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const closeEmailModal = useCallback(() => setIsEmailModalOpen(false), [setIsEmailModalOpen]);

  useEffect(() => {
    if (exportResult && exportResult.success) {
      closeEmailModal();
    }
  }, [exportResult, closeEmailModal]);
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openEmailModal = (): void => {
    setIsEmailModalOpen(true);
  };

  return (
    <Box>
      <Button
        variant="contained"
        className="iconButton--contained"
        startIcon={<Export />}
        endIcon={<ArrowDropDownIcon />}
        onClick={e => {
          openMenu(e);
        }}
      >
        Export
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {exportTypes.includes(ExportTypes.PRINT) && (
          <MenuItem
            onClick={() => {
              if (exportAction) {
                exportAction({ type: ExportTypes.PRINT });
              }

              closeMenu();
            }}
          >
            <Print />
            Print
          </MenuItem>
        )}
        {exportTypes.includes(ExportTypes.PDF) && (
          <MenuItem
            onClick={() => {
              if (exportAction) {
                exportAction({ type: ExportTypes.PDF });
              }

              closeMenu();
            }}
          >
            <PictureAsPdf />
            PDF
          </MenuItem>
        )}
        {exportTypes.includes(ExportTypes.EXCEL) && (
          <MenuItem
            onClick={() => {
              if (exportAction) {
                exportAction({ type: ExportTypes.EXCEL });
              }

              closeMenu();
            }}
          >
            <Description />
            Excel
          </MenuItem>
        )}
        {exportTypes.includes(ExportTypes.EMAIL) && (
          <MenuItem
            onClick={() => {
              openEmailModal();
            }}
          >
            <Email />
            Email
          </MenuItem>
        )}
        {exportTypes.includes(ExportTypes.CSV) && (
          <MenuItem
            onClick={() => {
              if (exportAction) {
                exportAction({ type: ExportTypes.CSV });
              }
            }}
          >
            <CSV />
            CSV
          </MenuItem>
        )}
      </Menu>

      <SendReportModal
        onClose={closeEmailModal}
        isOpen={isEmailModalOpen}
        onSubmit={exportAction || (() => {})}
      />
    </Box>
  );
};

export { ExportReport };
