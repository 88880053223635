import React from 'react';
import cx from 'classnames';
import { Box, Chip, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { IInvoiceUnitDTO, MenuItemTypes } from 'common/interfaces/invoices';
// messages
import {
  IMembershipInvoicePackage,
  IPackageService,
  IServiceInvoicePackage,
} from 'common/interfaces/membership';
import {
  FrequencyTypes,
  GeneralDurationTypes,
  IncludedFreePackageBillingTypes,
  IncludedPackageBillingType,
  PackageCostType,
} from 'modules/services/constants/packages';
import { CustomTheme } from 'common/ui/interfaces';
import { FormattedMessage } from 'react-intl';
import messages from 'common/messages/messages';
import { getQuantityMessage } from 'common/utils/displayServiceQuantity';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import InventoryInfoItem from 'common/components/InventoryInfoItem/InventoryInfoItem';
import InvoicePackageFeeItem from 'common/components/InvoiceOperating/InvoicePackageFeeItem/InvoicePackageFeeItem';
import { TooltipTypography } from 'common/components/index';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';
import { checkObjectPropertyExist, formatNumberToPrice } from 'common/utils';
import RemoveInvoiceItemController from '../RemoveInvoiceItemController/RemoveInvoiceItemController';
import InvoicePastDueItem from 'common/components/InvoiceOperating/InvoicePastDueItem/InvoicePastDueItem';
import InvoiceBillingItem from 'common/components/InvoiceOperating/InvoiceBillingItem/InvoiceBillingItem';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${theme.palette.borderColor?.light}`,
  },
  includedServiceName: {
    '&:before': {
      content: '"\\2022"',
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  },
  quantityLabel: {
    marginLeft: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  membershipChip: {
    marginLeft: theme.spacing(0.5),
    background: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
  },
  noPriceMarker: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: theme.spacing(1),
    alignItems: 'end',
  },
  frequencyType: {
    margin: theme.spacing(0, 1, 0, 0.5),
  },
  packagePriceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  packagePrice: {
    display: 'flex',
    alignItems: 'center',
  },
  taxedPrice: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
    fontSize: '0.75rem',
  },
  infoIcon: {
    width: '1rem',
    height: '1rem',
    color: theme.palette.darkBackground?.light,
    pointerEvents: 'auto',
    marginRight: theme.spacing(1),
  },
  packageInfoIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

interface IInvoiceServiceItem {
  invoicePackage: IMembershipInvoicePackage | IServiceInvoicePackage;
  pastDues?: Array<IInvoiceUnitDTO>;
  billingSchedules?: Array<IInvoiceUnitDTO>;
  onDelete?: () => void;
  onViewDetailedPackageInfo?: (packageId: string) => void;
  disabled?: boolean;
}

export default function InvoiceServiceItem({
  invoicePackage,
  pastDues,
  billingSchedules,
  onDelete,
  onViewDetailedPackageInfo,
  disabled,
}: IInvoiceServiceItem): JSX.Element {
  const { menuItemType } = invoicePackage;

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const getNumberedQuantityLabel = (packageService: IPackageService) => {
    const {
      limitedRedeemNumber,
      limited,
      service: { type, redeemType, redeemDurationUnit },
    } = packageService;
    const quantityLabel = renderIntlMessage(
      getQuantityMessage(type, redeemType, redeemDurationUnit, limited),
    );

    return limited ? `${limitedRedeemNumber} ${quantityLabel}` : quantityLabel;
  };

  const isMakeFirstPayment = (pkg: any) => {
    return !checkObjectPropertyExist(pkg, 'makeFirstPayment') || pkg.makeFirstPayment;
  };

  const renderPaymentGraceTooltip = (pkgConfiguration: any) => {
    const isMakeFirstPkgPayment = isMakeFirstPayment(pkgConfiguration);
    const {
      pricePerBilling,
      paymentGraceAllow,
      paymentGraceDuration,
      paymentGraceDurationNumber,
    } = pkgConfiguration;

    const paymentGraceDurationI18n = renderIntlMessage(
      GeneralDurationTypes.message(paymentGraceDuration),
    )?.toLowerCase();

    return (
      !isMakeFirstPkgPayment &&
      paymentGraceAllow && (
        <Tooltip
          title={
            <FormattedMessage
              {...messages.paymentGraceMightBePaidBody}
              values={{
                price: formatNumberToPrice(pricePerBilling),
                durationNumber: paymentGraceDurationNumber,
                duration: paymentGraceDurationI18n,
              }}
            />
          }
        >
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      )
    );
  };

  const renderServiceItem = (packageService: IPackageService) => {
    return (
      <Box marginY={2} key={packageService.id}>
        <Box display="flex" alignItems="center">
          <TooltipTypography
            ellipsized
            className={cx({
              [classes.includedServiceName]: packageService?.included,
            })}
          >
            {`${packageService.package?.title || packageService.service?.title}`}
          </TooltipTypography>

          {packageService.service && (
            <Typography component="p" color="textSecondary" className={classes.quantityLabel}>
              {`${getNumberedQuantityLabel(packageService)}`}
            </Typography>
          )}

          {packageService.package && (
            <Typography component="p" color="textSecondary" className={classes.quantityLabel}>
              {packageService.billingType === IncludedPackageBillingType.OwnBillingSettings
                ? FrequencyTypes.translate(String(packageService.package?.paymentSchedule))
                : IncludedFreePackageBillingTypes.translate(packageService.billingType)}
            </Typography>
          )}

          <Typography component="p" className={classes.noPriceMarker}>
            {packageService.package ? (
              <>
                <Typography component="span" className={classes.packagePrice}>
                  {renderPaymentGraceTooltip(packageService.package)}

                  <Typography component="span">
                    {`$${formatNumberToPrice(
                      isMakeFirstPayment(packageService.package) ||
                        !(packageService.package as any).paymentGraceAllow
                        ? ((packageService.package as any).amountToPay ||
                            packageService.package.pricePerBilling) -
                            (packageService.package.taxAmount || 0) || packageService.billingAmount
                        : 0,
                    )}`}
                  </Typography>
                </Typography>

                {!!packageService.package.taxAmount &&
                  (isMakeFirstPayment(packageService.package) ||
                    !(packageService.package as any).paymentGraceAllow) && (
                    <Typography component="span" className={classes.taxedPrice}>
                      {`$${formatNumberToPrice(
                        (packageService.package as any).amountToPay ||
                          packageService.package.pricePerBilling,
                      )}`}
                    </Typography>
                  )}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Box>

        {packageService.package && (
          <Box ml={3}>
            {packageService.package.services?.map(includedPackageService =>
              renderServiceItem(includedPackageService),
            )}

            {packageService.package.inventories?.map(packageInventory => (
              <InventoryInfoItem
                title={packageInventory.inventory?.title}
                number={packageInventory.number}
                frequency={packageInventory.frequency}
                key={packageInventory.id}
                isIncludedItem
              />
            ))}
          </Box>
        )}

        {!!packageService.package?.feeSection?.fees?.length && (
          <Box ml={3}>
            {packageService.package.feeSection.fees.map(packageFee => (
              <InvoicePackageFeeItem packageFee={packageFee} isIncludedItem />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const getBundleCost = () => {
    if (invoicePackage.costType === PackageCostType.Free) {
      return '$0.00';
    }

    return `$${formatNumberToPrice(
      isMakeFirstPayment(invoicePackage) || !(invoicePackage as any).paymentGraceAllow
        ? ((invoicePackage as any).amountToPay || invoicePackage.pricePerBilling) -
            (invoicePackage.taxAmount || 0)
        : 0,
    )}`;
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" overflow="hidden">
          {!!onDelete && (
            <RemoveInvoiceItemController
              onDelete={onDelete}
              disabled={disabled || menuItemType === MenuItemTypes.Membership}
            />
          )}

          <TooltipTypography ellipsized variant="h5">
            {invoicePackage.title}
          </TooltipTypography>

          {menuItemType === MenuItemTypes.Membership && (
            <Chip
              size="small"
              label={<FormattedMessage {...messages.membership} />}
              className={classes.membershipChip}
            />
          )}

          <Typography
            component="p"
            variant="h5"
            color="textSecondary"
            className={classes.frequencyType}
          >
            {FrequencyTypes.translate(String(invoicePackage.paymentSchedule))}
          </Typography>

          {!!onViewDetailedPackageInfo && (
            <IconButton
              size="small"
              type="button"
              color="primary"
              onClick={() => onViewDetailedPackageInfo((invoicePackage as any).instanceId)}
            >
              <InfoIcon className={classes.packageInfoIcon} />
            </IconButton>
          )}
        </Box>

        <Typography component="p" className={classes.packagePriceWrapper}>
          <Typography component="span" className={classes.packagePrice}>
            {renderPaymentGraceTooltip(invoicePackage)}

            <Typography component="span" variant="h5">
              {getBundleCost()}
            </Typography>
          </Typography>

          {!!invoicePackage.taxAmount && invoicePackage.makeFirstPayment && (
            <Typography component="span" className={classes.taxedPrice}>
              {`$${formatNumberToPrice(
                (invoicePackage as any).amountToPay || invoicePackage.pricePerBilling,
              )}`}
            </Typography>
          )}
        </Typography>
      </Box>

      {invoicePackage?.services?.map(packageService => renderServiceItem(packageService))}

      {invoicePackage?.inventories?.map(packageInventory => (
        <InventoryInfoItem
          title={packageInventory.inventory?.title}
          number={packageInventory.number}
          frequency={packageInventory.frequency}
          key={packageInventory.id}
        />
      ))}

      {invoicePackage?.fees?.map(packageFee => (
        <InvoicePackageFeeItem packageFee={packageFee} key={packageFee.id} />
      ))}

      {!!pastDues?.length &&
        pastDues.map(({ id, pastDue, pastDueResolve }) => (
          <InvoicePastDueItem
            pastDue={pastDue}
            isIncluded
            pastDueResolve={pastDueResolve}
            key={id}
          />
        ))}

      {!!billingSchedules?.length &&
        billingSchedules.map(({ id, billingSchedule }) => (
          <InvoiceBillingItem billing={billingSchedule} key={id} isIncluded />
        ))}
    </Box>
  );
}
