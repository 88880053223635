/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { Connect } from 'common/decorators';
import TableSection, {
  ITableSectionColumn,
  ITableHeaderColumn,
} from 'modules/reports/components/TableSection/TableSection';
// Selectors
import {
  selectPaymentsSummary,
  selectPaymentsSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
// Actions
import { fetchPaymentsSummary } from 'modules/reports/state/salesPOS/actions';
// Constants
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
// Interfaces
import {
  IPaymentStatisticItem,
  IPaymentStatisticItemImt,
  IPaymentSummaryImt,
} from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, IFooterCell, Order } from 'common/interfaces/table';
// Utils
import { formatPrice } from 'common/utils';
// Messages
import messages from 'modules/reports/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { selectRoot } from 'common/hooks/useRootSelector';

interface IPaymentsSummaryProps {
  mainTableParams: ITableParams;
  paymentsSummary?: IPaymentSummaryImt;
  paymentsSummaryLoading?: boolean;

  fetchPaymentsSummaryData?: (params?) => void;
}

interface ISortNames extends Omit<IPaymentStatisticItem, 'taxAmount' | 'totalAmount'> {
  tax: string;
  total: number;
}

@(Connect({
  mapStateToProps: () => ({
    paymentsSummary: selectRoot(selectPaymentsSummary()),
    paymentsSummaryLoading: selectRoot(selectPaymentsSummaryLoading()),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchPaymentsSummaryData: (requestOptions?) => dispatch(fetchPaymentsSummary(requestOptions)),
  }),
}) as any)
class PaymentsSummary extends Component<IPaymentsSummaryProps, ITableParams<ISortNames>> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<ISortNames>[];

  constructor(props: IPaymentsSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'type',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
      },
      {
        name: 'count',
        label: <FormattedMessage {...inputLabels.count} />,
        align: 'right',
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
      {
        name: 'discountAmount',
        label: <FormattedMessage {...inputLabels.discounts} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
      {
        name: 'taxAmount',
        label: <FormattedMessage {...inputLabels.tax} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
      {
        name: 'totalAmount',
        label: <FormattedMessage {...inputLabels.total} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
        sort: true,
      },
      {
        name: 'count',
        label: <FormattedMessage {...inputLabels.count} />,
        align: 'right',
        sort: true,
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        sort: true,
      },
      {
        name: 'discountAmount',
        label: <FormattedMessage {...inputLabels.discounts} />,
        align: 'right',
        sort: false,
      },
      {
        name: 'tax',
        label: <FormattedMessage {...inputLabels.tax} />,
        align: 'right',
        sort: true,
      },
      {
        name: 'total',
        label: <FormattedMessage {...inputLabels.total} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchPaymentsSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });

    if (fetchPaymentsSummaryData) {
      fetchPaymentsSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchPaymentsSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });

      if (fetchPaymentsSummaryData) {
        fetchPaymentsSummaryData({
          ...DEFAULT_TABLE_PARAMS,
          filters: mainTableParams.filters,
          page,
          perPage,
          orderBy,
          order,
        });
      }
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<ISortNames>): void => {
    this.setState(tableParams);
    const { fetchPaymentsSummaryData } = this.props;

    if (fetchPaymentsSummaryData) {
      fetchPaymentsSummaryData(tableParams);
    }
  };

  createTableFooter = (): IFooterCell[] => {
    const { paymentsSummary } = this.props;

    return [
      {
        id: 'title',
        label: <FormattedMessage {...messages.summaryTitle} />,
      },
      {
        id: 'count',
        label: `${paymentsSummary?.get('count')}`,
        align: 'right',
      },
      {
        id: 'amount',
        label: formatPrice(paymentsSummary?.get('amount') || ''),
        align: 'right',
      },
      {
        id: 'discountAmount',
        label: formatPrice(paymentsSummary?.get('discountAmount') || ''),
        align: 'right',
      },
      {
        id: 'taxAmount',
        label: formatPrice(paymentsSummary?.get('taxAmount') || ''),
        align: 'right',
      },
      {
        id: 'totalAmount',
        label: formatPrice(paymentsSummary?.get('totalAmount') || ''),
        align: 'right',
      },
    ];
  };

  render(): JSX.Element {
    const { paymentsSummary, paymentsSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<ISortNames, IPaymentStatisticItemImt>
        id="cash-payments-section"
        title={<FormattedMessage {...messages.paymentsSummaryTitle} />}
        headerColumns={this.headerSettings}
        columns={this.columnSettings}
        data={paymentsSummary?.get('statistics') as any} // TODO - PRM-3575 need types
        dataLoading={!!paymentsSummaryLoading}
        page={page}
        total={paymentsSummary?.size || 25}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        footerOptions={{ sticky: true, cells: this.createTableFooter() }}
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default PaymentsSummary;
