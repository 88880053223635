import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useRootSelector from 'common/hooks/useRootSelector';
// constants
import {
  PermissionsArrayClubLevel,
  PermissionsArrayPeakLevel,
} from 'modules/permissions/constants';
import { PermissionLevels } from 'common/constants/permissions';
// interfaces
import { IPolicyListItem } from 'modules/permissions/interfaces/permissions';
// messages
import commonMessages from 'common/messages/messages';
// state
import * as selectors from '../../state/policies/selectors';
import * as actions from '../../state/policies/actions';
// hooks
import { useAppDispatch } from 'store/hooks';
// components
import { DialogComponent, LoadingBackdrop } from 'common/components';
import { CollapsedComponent } from '..';
import { getPermissionsFilteredArray } from 'modules/permissions/utils/permission';
import { ISecurityPolicyDtoImt } from 'common/interfaces/permissions';

interface ViewPolicyModalProps {
  selectedRow: IPolicyListItem | null;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
}

export default function ViewPolicyModal({
  selectedRow,
  isOpen,
  onClose,
  isLoading,
}: ViewPolicyModalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [hideUnchecked, setHideUnchecked] = useState(true);

  const policyItem: ISecurityPolicyDtoImt = useRootSelector(selectors.selectPolicyItemData);

  useEffect(() => {
    if (selectedRow?.id && isOpen) dispatch(actions.fetchPolicyItem(selectedRow.id));
  }, [dispatch, selectedRow, isOpen]);

  const handleDialogClose = () => {
    onClose();
    dispatch(actions.resetPolicyItemData());
  };

  let policyLevel = PermissionLevels.CORPORATE;

  if (PermissionsArrayClubLevel[policyItem.get('permissionGroup')]) {
    policyLevel = PermissionLevels.CLIENT;
  }

  if (PermissionsArrayPeakLevel[policyItem.get('permissionGroup')]) {
    policyLevel = PermissionLevels.PEAK;
  }

  const permissionsArray = getPermissionsFilteredArray(policyLevel, hideUnchecked, [
    policyItem.get('permissionGroup'),
  ]);

  return (
    <DialogComponent
      title={selectedRow?.title}
      submitBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
      size="sm"
      isOpen={isOpen}
      onClose={handleDialogClose}
      disableFullScreenBehavior
      submitBtn={false}
      cancelBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
    >
      <LoadingBackdrop isLoading={isLoading} />
      {!isLoading && (
        <CollapsedComponent
          permissions={permissionsArray}
          permissionSet={policyItem.get('permissionSet')?.toJS()}
          onHideUnchecked={setHideUnchecked}
          hideUnchecked={hideUnchecked}
        />
      )}
    </DialogComponent>
  );
}
