import { DeepPartial } from 'react-hook-form';
import { createAction } from '@reduxjs/toolkit';
// constants
import { actionTypes } from '../../constants/packages';
// interfaces
import {
  IBillingOption,
  IEditPackageDetails,
  IPackageServicesPackagesList,
  IBundleView,
  IPaginatedPackagesList,
} from '../../interfaces/packages';
import { ITableParams } from 'common/interfaces/table';
// services
import Services from 'services/network';
import { ISuccessResponse, PersonSearchParamsType } from 'common/interfaces/http';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ActionResult } from 'common/constants/globalConstants';
import { createUpdateServiceLoading } from '../services/actions';
import { IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IPackageRCodeListItem } from '../../../pos-settings/interfaces/revenueCodes';
import { INamedEntity } from 'common/interfaces/common';
import { IFee } from '../../interfaces/fees';

export const resetPackageState = createAction<void>(actionTypes.RESET_PACKAGE_STATE);
const fetchPackagesListAction = createAction<IPaginatedPackagesList>(
  actionTypes.FETCH_PACKAGES_LIST,
);
const fetchPackagesListLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGES_LIST_LOADING,
);
export const fetchPackagesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPackagesListLoadingAction(true));

    try {
      const paginatedData = await Services.ServicesList.getPackagesList(requestOptions);
      dispatch(fetchPackagesListAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPackagesListLoadingAction(false));
    }
  };
};

const changePackageStatusAction = createAction<ISuccessResponse>(actionTypes.CHANGE_PACKAGE_STATUS);
const changePackageStatusLoadingAction = createAction<boolean>(
  actionTypes.CHANGE_PACKAGE_STATUS_LOADING,
);
export const resetChangePackageStatus = createAction(actionTypes.RESET_CHANGE_PACKAGE_STATUS);
export const changePackageStatus = (packageId: string, isActive: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changePackageStatusLoadingAction(true));

    try {
      const result = await Services.ServicesList.changePackageStatus(packageId, isActive);
      dispatch(changePackageStatusAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changePackageStatusLoadingAction(false));
    }
  };
};

const clonePackageAction = createAction<ActionResult>(actionTypes.CLONE_PACKAGE);
const clonePackageLoadingAction = createAction<boolean>(actionTypes.CLONE_PACKAGE_LOADING);
export const resetClonePackage = createAction(actionTypes.RESET_CLONE_PACKAGE);
export const clonePackage = (packageId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(clonePackageLoadingAction(true));

    try {
      await Services.ServicesList.clonePackage(packageId);
      dispatch(clonePackageAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(clonePackageLoadingAction(false));
    }
  };
};

export const createUpdatePackageLoading = createAction<boolean>(
  actionTypes.CREATE_UPDATE_PACKAGE_LOADING,
);
export const createUpdatePackageResult = createAction<ActionResult | null>(
  actionTypes.CREATE_UPDATE_PACKAGE_RESULT,
);
export const createServicePackage = (
  formData: DeepPartial<IEditPackageDetails>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createUpdatePackageLoading(true));

    try {
      await Services.ServicePackage.createServicePackage(formData);
      dispatch(createUpdatePackageResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createUpdateServiceLoading(false));
    }
  };
};

export const updateServicePackage = (
  packageId: string,
  formData: DeepPartial<IEditPackageDetails>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createUpdatePackageLoading(true));

    try {
      await Services.ServicePackage.updateServicePackage(packageId, formData);
      dispatch(createUpdatePackageResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createUpdateServiceLoading(false));
    }
  };
};

const fetchPackageLoading = createAction<boolean>(actionTypes.FETCH_PACKAGE_LOADING);
const fetchPackageAction = createAction<IEditPackageDetails>(actionTypes.FETCH_PACKAGE);
export const fetchPackage = (packageId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPackageLoading(true));

    try {
      const response = await Services.ServicesList.getPackageById(packageId);

      const outOfTermBillingOption = response.billingSection?.billingOptions.find(
        item => item.isOutOfTerm,
      );

      if (outOfTermBillingOption) {
        response.billingSection.outOfTermBillingOption = outOfTermBillingOption;
        response.billingSection.billingOptions = response.billingSection?.billingOptions.filter(
          item => !item.isOutOfTerm,
        );
      }

      dispatch(fetchPackageAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPackageLoading(false));
    }
  };
};

// revenue-codes list
const packageRCodesListAction = createAction<IPackageRCodeListItem>(
  actionTypes.FETCH_PACKAGE_RCODES,
);
const packageRCodesListLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGE_RCODES_LOADING,
);
export const resetPackageRCodes = createAction(actionTypes.RESET_PACKAGE_RCODES);
export const fetchPackageRCodes = (title?: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packageRCodesListLoadingAction(true));

    try {
      const resultList = await Services.ServicePackage.getPackageRcodes(title);
      dispatch(packageRCodesListAction(resultList));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packageRCodesListLoadingAction(false));
    }
  };
};

// employees
const packageEmployeesAction = createAction<IPrimaryMemberInfo[]>(
  actionTypes.FETCH_PACKAGE_EMPLOYEES,
);
const packageEmployeesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGE_EMPLOYEES_LOADING,
);

// TODO - not used
// export const resetPackageEmployees = createAction(actionTypes.RESET_PACKAGE_EMPLOYEES);
export const fetchPackageEmployees = (
  searchStr: string,
  field: PersonSearchParamsType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packageEmployeesLoadingAction(true));

    try {
      const result = await Services.Members.getSearchMembers(searchStr, field);
      dispatch(packageEmployeesAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packageEmployeesLoadingAction(false));
    }
  };
};

// services list
const packageServicesPackagesAction = createAction<IPackageServicesPackagesList>(
  actionTypes.FETCH_PACKAGE_SERVICES_PACKAGES,
);
const packageServicesPackagesLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGE_SERVICES_PACKAGES_LOADING,
);
export const resetPackageServicesAndPackages = createAction(
  actionTypes.RESET_PACKAGE_SERVICES_PACKAGES,
);
export const fetchPackageServicesAndPackages = (
  title = '',
  packageId: string,
  relatedPackageIds: string[],
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packageServicesPackagesLoadingAction(true));

    try {
      const response = await Services.ServicesList.getPackageServicesPackagesList(
        title,
        packageId,
        relatedPackageIds,
      );
      dispatch(packageServicesPackagesAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packageServicesPackagesLoadingAction(false));
    }
  };
};

// billing options

const fetchBillingOptionsAction = createAction<INamedEntity[]>(actionTypes.FETCH_BILLING_OPTIONS);
const fetchBillingOptionsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_BILLING_OPTIONS_LOADING,
);
export const resetBillingOptions = createAction(actionTypes.RESET_BILLING_OPTIONS);

export const fetchBillingOptions = (packageId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchBillingOptionsLoadingAction(true));

    try {
      const billingOptions = await Services.ServicePackage.getBillingOptions(packageId);

      dispatch(fetchBillingOptionsAction(billingOptions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchBillingOptionsLoadingAction(false));
    }
  };
};

const fetchBillingOptionByIdAction = createAction<IBillingOption>(actionTypes.FETCH_BILLING_OPTION);
const fetchBillingOptionByIdLoadingAction = createAction<boolean>(
  actionTypes.FETCH_BILLING_OPTION_LOADING,
);
export const resetBillingOptionById = createAction(actionTypes.RESET_BILLING_OPTION);

export const fetchBillingOptionById = (optionId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchBillingOptionByIdLoadingAction(true));

    try {
      const billingOption = await Services.ServicePackage.getBillingOptionById(optionId);

      dispatch(fetchBillingOptionByIdAction(billingOption));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchBillingOptionByIdLoadingAction(false));
    }
  };
};

// fees list
const packageFeesAction = createAction<Array<IFee>>(actionTypes.FETCH_PACKAGE_FEES);
const packageFeesLoadingAction = createAction<boolean>(actionTypes.FETCH_PACKAGE_FEES_LOADING);
export const resetPackageFees = createAction(actionTypes.RESET_PACKAGE_FEES);
export const fetchPackageFees = (title = ''): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packageFeesLoadingAction(true));

    try {
      const response = await Services.Fees.getPackageFeesList(title);
      dispatch(packageFeesAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packageFeesLoadingAction(false));
    }
  };
};

// inventory list
const packageInventoryAction = createAction<INamedEntity[]>(actionTypes.FETCH_PACKAGE_INVENTORY);
const packageInventoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGE_INVENTORY_LOADING,
);
export const resetPackageInventory = createAction(actionTypes.RESET_PACKAGE_INVENTORY);
export const fetchPackageInventory = (title = ''): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packageInventoryLoadingAction(true));

    try {
      const response = await Services.ServicesList.getInventoryList(title);
      dispatch(packageInventoryAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packageInventoryLoadingAction(false));
    }
  };
};

// package info action
const packagePackageInfoAction = createAction<IBundleView>(actionTypes.FETCH_PACKAGE_INFO);
const packagePackageInfoLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PACKAGE_INFO_LOADING,
);
export const resetPackageInfo = createAction(actionTypes.RESET_PACKAGE_INFO);
export const fetchPackageInfo = (packageId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(packagePackageInfoLoadingAction(true));

    try {
      const response = await Services.ServicesList.getSubBundleConfiguration(packageId);

      dispatch(packagePackageInfoAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(packagePackageInfoLoadingAction(false));
    }
  };
};
