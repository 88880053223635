// libraries
import React, { FC } from 'react';
// constants
import { EditPackageStep, StepToggleAction } from 'modules/services/constants/packages';
// interfaces
import { IEditPackageDetails } from 'modules/services/interfaces/packages';
// components
import GeneralInfoStep from './GeneralInfoStep/GeneralInfoStep';
import BillingStep from './BillingStep/BillingStep';
import InventoryStep from './InventoryStep/InventoryStep';
import MembershipStep from './MembershipStep/MembershipStep';
import ServicesStep from './ServicesStep/ServicesStep';
import FeesStep from './FeesStep/FeesStep';

interface INewPackageFormProps {
  currentStep: EditPackageStep;
  editPackageFormData: Partial<IEditPackageDetails>;
  disabledSteps: Array<EditPackageStep>;
  toggleStep: (step: EditPackageStep, action: StepToggleAction) => void;
  renderFooter: (onSubmit?, onNext?, onBack?, disabled?) => JSX.Element;
  handleChange: (data, errors?) => void;
  onPackageSubmit: (data, submitErrors) => void;
  refToStepForm: any;
  submitAttempted: boolean;
}

const EditPackageForm: FC<INewPackageFormProps> = ({
  editPackageFormData,
  currentStep,
  renderFooter,
  disabledSteps,
  toggleStep,
  handleChange,
  onPackageSubmit,
  refToStepForm,
  submitAttempted,
}) => {
  const showStep = () => {
    switch (currentStep) {
      case EditPackageStep.General: {
        if (editPackageFormData.generalSection) {
          return (
            <GeneralInfoStep
              ref={refToStepForm}
              editPackageSectionFormData={editPackageFormData.generalSection}
              renderFooter={renderFooter}
              handleChange={handleChange}
              disabledSteps={disabledSteps}
              toggleStep={toggleStep}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
            />
          );
        }

        return null;
      }
      case EditPackageStep.Billing: {
        if (editPackageFormData.billingSection) {
          return (
            <BillingStep
              ref={refToStepForm}
              editPackageSectionFormData={editPackageFormData.billingSection}
              renderFooter={renderFooter}
              handleChange={handleChange}
              disabledSteps={disabledSteps}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
              durationType={editPackageFormData.generalSection?.durationType || ''}
              durationEditableNumber={
                editPackageFormData.generalSection?.durationEditableNumber?.value || ''
              }
              startDate={editPackageFormData.generalSection?.customDates?.startDate as string}
              endDate={editPackageFormData.generalSection?.customDates?.endDate as string}
            />
          );
        }

        return null;
      }
      case EditPackageStep.Membership: {
        if (editPackageFormData.membershipSection) {
          return (
            <MembershipStep
              ref={refToStepForm}
              editPackageSectionFormData={editPackageFormData.membershipSection}
              renderFooter={renderFooter}
              handleChange={handleChange}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
            />
          );
        }

        return null;
      }
      case EditPackageStep.Inventory: {
        if (editPackageFormData.inventorySection) {
          return (
            <InventoryStep
              ref={refToStepForm}
              editPackageSectionFormData={editPackageFormData.inventorySection}
              renderFooter={renderFooter}
              handleChange={handleChange}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
            />
          );
        }

        return null;
      }
      case EditPackageStep.Services: {
        if (editPackageFormData.serviceSection) {
          return (
            <ServicesStep
              ref={refToStepForm}
              packageId={editPackageFormData?.id}
              editPackageSectionFormData={editPackageFormData.serviceSection}
              renderFooter={renderFooter}
              handleChange={handleChange}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
              validAvailabilityRange={editPackageFormData.generalSection?.dayTimeAvailDtoList}
            />
          );
        }

        return null;
      }
      case EditPackageStep.Fees: {
        if (editPackageFormData.feesSection) {
          return (
            <FeesStep
              ref={refToStepForm}
              editPackageSectionFormData={editPackageFormData.feesSection}
              durationType={editPackageFormData.generalSection?.durationType}
              durationEditableNumber={
                editPackageFormData.generalSection?.durationEditableNumber?.value
              }
              renderFooter={renderFooter}
              handleChange={handleChange}
              onPackageSubmit={onPackageSubmit}
              submitAttempted={submitAttempted}
            />
          );
        }

        return null;
      }
      default: {
        return null;
      }
    }
  };

  return showStep();
};
export default EditPackageForm;
